import { Action } from '@ngrx/store'
import { ISyncSaleNoteResponse, ISaleNote, IAgentSettings } from 'src/types'

export enum SyncSaleNoteActionTypes {
    SyncSaleNote = '[SyncSaleNote] SyncSaleNote',
    SyncSaleNoteSuccess = '[SyncSaleNote] SyncSaleNotes Success',
    SyncSaleNoteFailure = '[SyncSaleNote] SyncSaleNotes Failure',
    AppendSaleNote = '[SyncSaleNote] Append Sale Note',
}

export class SyncSaleNote implements Action {
    readonly type = SyncSaleNoteActionTypes.SyncSaleNote
    constructor(
        public payload: {
            data: { saleNote: ISaleNote }
        }
    ) {}
}

export class SyncSaleNoteSuccess implements Action {
    readonly type = SyncSaleNoteActionTypes.SyncSaleNoteSuccess
    constructor(public payload: { data: ISyncSaleNoteResponse }) {}
}

export class SyncSaleNoteFailure implements Action {
    readonly type = SyncSaleNoteActionTypes.SyncSaleNoteFailure
    constructor(public payload: { error: any }) {}
}

export class AppendSaleNote implements Action {
    readonly type = SyncSaleNoteActionTypes.AppendSaleNote
    constructor(public payload: { data: ISaleNote }) {}
}

export type SyncSaleNoteActions =
    | SyncSaleNote
    | SyncSaleNoteSuccess
    | SyncSaleNoteFailure
    | AppendSaleNote
