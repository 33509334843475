import { Action } from '@ngrx/store'
import { IGetAgentDataResponse } from 'src/types'

export enum AgentDataActionTypes {
    LoadAgentData = '[AgentData] Load AgentData',
    LoadAgentDataSuccess = '[AgentData] Load AgentData Success',
    LoadAgentDataFailure = '[AgentData] Load AgentData Failure',
    LoadAgentDataFromPouch = '[AgentData] Load AgentData from Pouch',
    LoadAgentDataFromPouchSuccess = '[AgentData] Load AgentData from Pouch Success',
    LoadAgentDataFromPouchFailure = '[AgentData] Load AgentData from Pouch Failure',
    ClearAgentDataFromPouch = '[AgentData] Clear Agent Data from Pouch',
    LoadUpdateAgentData = '[AgentData] Load And Update Agent Data'
}

export class LoadAgentData implements Action {
    readonly type = AgentDataActionTypes.LoadAgentData
    constructor(
        public payload: {
            data: {
                pstrFullRefresh: boolean,
                agentCode?: string
            }
        }
    ) { }
}

export class LoadAgentDataSuccess implements Action {
    readonly type = AgentDataActionTypes.LoadAgentDataSuccess
    constructor(
        public payload: {
            data: IGetAgentDataResponse
        }
    ) { }
}

export class LoadAgentDataFailure implements Action {
    readonly type = AgentDataActionTypes.LoadAgentDataFailure
    constructor(public payload: { error: any }) { }
}

export class LoadAgentDataFromPouch implements Action {
    readonly type = AgentDataActionTypes.LoadAgentDataFromPouch
}

export class LoadAgentDataFromPouchSuccess implements Action {
    readonly type = AgentDataActionTypes.LoadAgentDataFromPouchSuccess
    constructor(
        public payload: {
            data: IGetAgentDataResponse
        }
    ) { }
}

export class LoadAgentDataFromPouchFailure implements Action {
    readonly type = AgentDataActionTypes.LoadAgentDataFromPouchFailure
    constructor(public payload: { error: any }) { }
}

export class ClearAgentDataFromPouch implements Action {
    readonly type = AgentDataActionTypes.ClearAgentDataFromPouch
}

export class LoadUpdateAgentData implements Action {
    readonly type = AgentDataActionTypes.LoadUpdateAgentData
}

export type AgentDataActions =
    | LoadAgentData
    | LoadAgentDataSuccess
    | LoadAgentDataFailure
    | LoadAgentDataFromPouch
    | LoadAgentDataFromPouchSuccess
    | LoadAgentDataFromPouchFailure
    | ClearAgentDataFromPouch
    | LoadUpdateAgentData
