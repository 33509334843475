import { Action } from '@ngrx/store'
import {
    IGetActiveSalesResponse,
    IGetLotsResponse,
    ISaveLotsRequest,
    IActiveSaleLot
} from 'src/types'

export enum ActiveSaleActionTypes {
    LoadActiveSales = '[ActiveSale] Load ActiveSales',
    LoadActiveSalesSuccess = '[ActiveSale] Load ActiveSales Success',
    LoadActiveSalesFailure = '[ActiveSale] Load ActiveSales Failure',
    LoadLots = '[ActiveSale] Load Lots',
    LoadLotsSuccess = '[ActiveSale] Load Lots Success',
    LoadLotsFailure = '[ActiveSale] Load Lots Failure',
    SaveLots = '[ActiveSale] Save Lots',
    SaveLotsResult = '[ActiveSale] Save Lots Success',
    GetRelatedData = '[ActiveSale] Get Related Data',
    GetRelatedDataSuccess = '[ActiveSale] Get Related Data Success',
    GetRelatedDataFailure = '[ActiveSale] Get Related Data Failure',
    SelectActiveSale = '[ActiveSale] Set Active Sale'
}

export class LoadActiveSales implements Action {
    readonly type = ActiveSaleActionTypes.LoadActiveSales
}

export class LoadActiveSalesSuccess implements Action {
    readonly type = ActiveSaleActionTypes.LoadActiveSalesSuccess
    constructor(public payload: { data: IGetActiveSalesResponse }) { }
}

export class LoadActiveSalesFailure implements Action {
    readonly type = ActiveSaleActionTypes.LoadActiveSalesFailure
    constructor(public payload: { error: any }) { }
}

export class LoadLots implements Action {
    readonly type = ActiveSaleActionTypes.LoadLots
    constructor(public payload: { data: { saleID: string } }) { }
}

export class LoadLotsSuccess implements Action {
    readonly type = ActiveSaleActionTypes.LoadLotsSuccess
    constructor(
        public payload: { data: { response: IGetLotsResponse; saleID: string } }
    ) { }
}

export class LoadLotsFailure implements Action {
    readonly type = ActiveSaleActionTypes.LoadLotsFailure
    constructor(public payload: { error: any }) { }
}

export class SaveLots implements Action {
    readonly type = ActiveSaleActionTypes.SaveLots
    constructor(public payload: { data: ISaveLotsRequest, toastMessage?: string }) { }
}

export class SaveLotsResult implements Action {
    readonly type = ActiveSaleActionTypes.SaveLotsResult
    constructor(
        public payload: {
            data: {
                result: 'OK' | 'NG'
                Lots: IActiveSaleLot[]
                pstrSaleID: string
            },
            toastMessage?: string
        }
    ) { }
}

export class GetRelatedData implements Action {
    readonly type = ActiveSaleActionTypes.GetRelatedData
    constructor(public payload: { data: { pstrSaleID: string } }) { }
}

export class GetRelatedDataSuccess implements Action {
    readonly type = ActiveSaleActionTypes.GetRelatedDataSuccess
    constructor(public payload: { data: any }) { }
}

export class SelectActiveSale implements Action {
    readonly type = ActiveSaleActionTypes.SelectActiveSale
    constructor(public payload: { data: string }) { }
}

export type ActiveSaleActions =
    | LoadActiveSales
    | LoadActiveSalesSuccess
    | LoadActiveSalesFailure
    | LoadLots
    | LoadLotsSuccess
    | LoadLotsFailure
    | SaveLots
    | SaveLotsResult
    | GetRelatedData
    | GetRelatedDataSuccess
    | SelectActiveSale
