import { Component, OnInit, OnDestroy } from '@angular/core';
import * as packageJSON from 'src/../package.json';
import { Platform, ToastController, MenuController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Store } from '@ngrx/store';
import { IAppState, ICountDown } from 'src/types';
import { environment } from '../environments/environment';
import {
    LoadSelectedSaleNote,
    LoadSaleNotesFetched,
} from './actions/sale-note.actions';
import { HttpClient } from '@angular/common/http';
import { fromEvent, Observable, Subscription, Subject } from 'rxjs';
import { Offline, Online } from './actions/network.actions';
import { take, skip, takeUntil } from 'rxjs/operators';
import { ToastOptions } from '@ionic/core';
import { LoadToastMessage } from './actions/toast-message.actions';
import { Router } from '@angular/router';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
// import { ApiService } from './services/api.service'
import { Login, Logout } from './actions/user.actions';
import {
    toDataURL,
    isUpToDate,
    afterServiceWorkerUpdated,
    obfuscatedPw
} from './utilities';
import { LoadSavedLogo, LoadLogo } from './actions/pdf.actions';
import { ClockCheckerService } from './services/clock-checker.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    countdownInterval = 1000; // Update every second
    public appPages = [
        {
            title: 'Home',
            url: '/home',
            icon: 'home',
        },
        {
            title: 'View Clients',
            url: '/view-clients',
            icon: 'people-circle-outline',
        }
    ];

    online: Observable<boolean>;
    toastSubscription: Subscription;
    clockSubscription?: Subscription;
    clockError?: boolean;
    clockErrorAlert = false;
    loggedIn = true;

    countdownSubscription?: Subscription;
    countdownState: ICountDown | undefined = undefined;
    stopCountdown$ = new Subject<void>();

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private store: Store<IAppState>,
        private network: Network,
        private http: HttpClient,
        public toastController: ToastController,
        public alertController: AlertController,
        private router: Router,
        private session: SessionStorageService,
        private local: LocalStorageService,
        private menu: MenuController,
        private clockChecker: ClockCheckerService
    ) {
        this.testNetwork();
        this.initializeApp();
    }

    async toggleNetworkForDevelopment() {
        const online = await this.online.pipe(take(1)).toPromise();
        if (online) {
            this.store.dispatch(new Offline());
        } else {
            this.store.dispatch(new Online());
        }
    }

    async testNetwork() {
        const appIsOnDevice = !this.platform.url().startsWith('http');

        if (appIsOnDevice) {
            this.network.onDisconnect().subscribe(() => {
                this.store.dispatch(new Offline());
            });
            this.network.onConnect().subscribe(() => {
                this.store.dispatch(new Online());
            });
        } else {
            fromEvent(window, 'offline').subscribe(() => {
                this.store.dispatch(new Offline());
            });
            fromEvent(window, 'online').subscribe(() => {
                this.store.dispatch(new Online());
            });
        }

        try {
            const response = await this.http
                .get('https://relaytest.saleg8.net/ping', {
                    responseType: 'text',
                })
                .toPromise();
            this.store.dispatch(new Online());
        } catch (error) {
            console.error(error);
            this.store.dispatch(new Offline());
        }
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.platform.resume.subscribe(() => {
                this.store.dispatch(new LoadSaleNotesFetched());
            });
            if (this.platform.is('desktop')) {
                return;
            }
            this.statusBar.show();
            if (this.platform.is('android')) {
                this.statusBar.styleBlackTranslucent();
            } else {
                this.statusBar.styleDefault();
                this.statusBar.overlaysWebView(true);
            }
            this.splashScreen.hide();
        });
    }

    getUserData() {
        return {
            CID: this.local.get('CID'),
            agentCode: this.local.get('agentCode'),
            password: this.session.get('password'),
        };
    }

    async logout() {
        this.local.set('lastUrl', this.router.url);
        const set = (s, v) => {
            this.session.set(s, v);
        };
        set('password', '');
        this.router.navigate(['login']);

        const menu = await this.menu.getOpen();
        if (menu) {
            menu.close();
        }
        this.store.dispatch(new Logout());
        window.location.reload();
    }

    async checkForUpdate() {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            const interval = setInterval(async () => {
                const updated = await isUpToDate(packageJSON.version);
                if (!updated) {
                    clearInterval(interval);
                    afterServiceWorkerUpdated(() => {
                        this.store.dispatch(
                            new LoadToastMessage({
                                data: {
                                    message: 'Updates available',
                                    buttons: [
                                        {
                                            side: 'start',
                                            icon: 'reload',
                                            text: 'Reload',
                                            handler: () => {
                                                window.location.assign(
                                                    '/?r=' + Math.random()
                                                );
                                            },
                                        },
                                        {
                                            text: 'Cancel',
                                            role: 'cancel',
                                            handler: () => { },
                                        },
                                    ],
                                },
                            })
                        );
                    });
                }
            }, 1000 * 60);
        }
    }

    redirectHttps() {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            if (environment.production) {
                if (window.location.protocol === 'http:') {
                    window.location.protocol = 'https:';
                    window.location.reload();
                }
            }
        }
    }

    presentClockErrorAlert() {
        this.clockErrorAlert = true;
        this.alertController.create({
            header: 'Session Expired',
            message: 'Your session has expired. You should log out and log back in before proceeding.',
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                    handler: () => {
                        this.clockErrorAlert = false;
                    }
                },
                {
                    text: 'Log Out',
                    handler: () => {
                        this.logout();
                    }
                }
            ],
        }).then((alert) => {
            alert.present()
        });
    }

    async presentSessionInfoAlert() {
        const alert = await this.alertController.create({
          header: 'Session Expiry',
          message: 'As an enhanced security feature your logged in session has an expiry time. Please make sure you sync/send any updates to saleG8 before your current session expires.',
          buttons: ['OK'],
        });
    
        await alert.present();
      }

    ngOnInit() {
        this.redirectHttps();
        this.checkForUpdate();
        // load saved data from pouch
        // load agent data
        // load agent settings
        // load sale notes

        this.countdownSubscription = this.clockChecker.getClockCounter().pipe(
            takeUntil(this.stopCountdown$)
        ).subscribe((result) => {
            this.countdownState = result;
            if (result.expired === true) {
                this.stopCountdown$.next();
                this.stopCountdown$.complete();
            }
        })

        this.clockSubscription = this.clockChecker.clockError().subscribe((error) => {
            if (error && !this.clockError) {
                this.clockError = true;
            }
            if (error && !this.clockErrorAlert) {
                this.presentClockErrorAlert();
            }
        });

        this.store.select('loginResponse').subscribe((loginResponse) => {
            if (!loginResponse) {
                return;
            }

            const mappedLoginResponse = Object.values(loginResponse.Menu)
                .map(({ ScreenName }) => ScreenName);

            if (
                mappedLoginResponse
                    .includes('Sale Notes')
            ) {
                const newPages = [
                    {
                        title: 'View Sale Notes',
                        url: '/view-sale-notes',
                        icon: 'list-outline',
                    },
                    {
                        title: 'Enter Sale Note',
                        url: '/enter-sale-note',
                        icon: 'add-circle',
                    },
                ];
                newPages.forEach((page, idx) => {
                    if (!this.appPages.find((p) => p.url === page.url)) {
                        this.appPages.splice(1, 0, page);
                    }
                });
            }

            if (
                mappedLoginResponse
                    .includes('Point of Sale')
            ) {
                if (!this.appPages.find((p) => p.url === '/point-of-sale')) {
                    this.appPages.splice(this.appPages.length, 0, {
                        title: 'Point of Sale',
                        url: '/point-of-sale',
                        icon: 'card',
                    });
                }
            }

            if (
                mappedLoginResponse
                    .includes('Reports')
            ) {
                if (!this.appPages.find((p) => p.url === '/reports')) {
                    this.appPages.splice(this.appPages.length, 0, {
                        title: 'Reports',
                        url: '/reports',
                        icon: 'document-text',
                    });
                }
            }

            if (
                mappedLoginResponse
                    .includes('Statistics')
            ) {
                if (!this.appPages.find((p) => p.url === '/graphs')) {
                    this.appPages.splice(this.appPages.length, 0, {
                        title: 'Graphs',
                        url: '/graphs',
                        icon: 'bar-chart',
                    });
                }
            }

            if (
                mappedLoginResponse
                    .includes('Administration')
            ) {
                this.appPages.splice(this.appPages.length, 0, {
                    title: 'Administration',
                    url: '/admin',
                    icon: 'build',

                });
            }
        });

        this.store.select('loggedIn').subscribe(async (loggedIn) => {
            this.loggedIn = loggedIn;

            // await new Promise(resolve => setTimeout(resolve, 1000))
            if (this.platform.is('desktop')) {
                return;
            }

            if (loggedIn) {
                const menu = await this.menu.getMenus()[0];
                if (menu) {
                    menu.open();
                }
            } else if (!loggedIn) {
                const menu = await this.menu.getOpen();
                if (menu) {
                    menu.close();
                }
            }
        });

        const userData = this.getUserData();
        const defined = Object.values(userData).every(
            (s) => typeof s === 'string' && s.length > 0
        );

        this.online = this.store.select('online');

        const offlineNotification = new LoadToastMessage({
            data: {
                duration: 1000,
                color: 'warning',
                message: 'Internet is disconnected',
            },
        });

        this.online.pipe(take(1)).subscribe((online) => {
            if (!online) {
                if (!defined) {
                    this.router.navigate(['login']);
                }
                this.store.dispatch(offlineNotification);
                this.store.dispatch(new LoadSavedLogo());
            } else {
                if (userData.CID) {
                    try {
                        toDataURL(
                            `https://relayprod.saleg8.net/logos/${userData.CID}/logo.png`,
                            (logo) => {
                                this.store.dispatch(new LoadLogo({ logo }));
                            }
                        );
                    } catch (e) {
                        console.error('No logo', e);
                    }
                }

                if (defined) {
                    this.store.dispatch(
                        new Login({
                            data: {
                                redirect: false,
                                CID: userData.CID,
                                agentCode: userData.agentCode,
                                password: obfuscatedPw(userData.password, false),
                            },
                        })
                    );
                } else {
                    this.router.navigate(['login']);
                }
            }
        });
        this.online.pipe(skip(1)).subscribe((online) => {
            if (online) {
                this.store.dispatch(
                    new LoadToastMessage({
                        data: {
                            duration: 1000,
                            color: 'success',
                            message: 'Internet is connected',
                        },
                    })
                );
            } else {
                this.store.dispatch(offlineNotification);
            }
        });

        this.toastSubscription = this.store
            .select('toastMessage')
            .subscribe((options) => {
                this.controlToast(options);
            });

        this.store.select('agentSettings')
            .subscribe((agentSettings) => {
                const { CID, agentCode } = agentSettings.settingsForm;
                const settingsDefined = [CID, agentCode].every(
                    (s) => typeof s === 'string' && s.length > 0
                );

                if (settingsDefined) {
                    this.store.dispatch(new LoadSelectedSaleNote());
                    try {
                        toDataURL(
                            `https://relayprod.saleg8.net/logos/${CID}/logo.png`,
                            (logo) => {
                                this.store.dispatch(new LoadLogo({ logo }));
                            }
                        );
                    } catch (e) {
                        console.error('No logo', e);
                    }
                }
            })
    }

    async controlToast(options: ToastOptions) {
        this.closeToast();
        if (options) {
            const toast = await this.toastController.create(options);
            await toast.present();
        }
    }

    async closeToast() {
        try {
            const toast = await this.toastController.getTop();
            if (toast) {
                toast.dismiss();
            }
        } catch (error) { }
    }

    async showSessionInfo() {
        await this.presentSessionInfoAlert();
    }

    ngOnDestroy() {
        this.clockSubscription?.unsubscribe();
        this.countdownSubscription?.unsubscribe()
    }
}
