import { Action } from '@ngrx/store'
import { ISaleNote, ILot, ICustomizations } from 'src/types'

export enum SaleNoteActionTypes {
    LoadSaleNotes = '[SaleNote] Load SaleNotes',
    LoadSaleNotesSuccess = '[SaleNote] Load SaleNotes Success',
    LoadSaleNotesFailure = '[SaleNote] Load SaleNotes Failure',
    LoadSaleNotesFromPouch = '[SaleNotes] Load SaleNotes From Pouch',
    LoadSaleNotesFromPouchSuccess = '[SaleNotes] Load SaleNotes From Pouch Success',
    LoadSaleNotesFromPouchFailure = '[SaleNotes] Load SaleNotes From Pouch Failure',
    SelectSaleNote = '[SaleNotes] Select SaleNote',
    NewSaleNote = '[SaleNote] New SaleNote',
    ValidateSaleNote = '[SaleNote] Validate SaleNote',
    DeleteSaleNote = '[SaleNote] Delete SaleNote',
    IncrementSaleNoteNumber = '[SaleNote] Increment SaleNote Number',
    AddLot = '[SaleNote] Add Lot',
    LoadSelectedSaleNote = '[SaleNote] LoadSelectedSaleNote',
    LoadSelectedSaleNoteSuccess = '[SaleNote] LoadSelectSaleNoteSuccess',
    ResetValidation = '[SaleNote] Reset Sale Note Validation',
    LoadNextSaleNoteNumber = '[SaleNote] Load Next Sale Note Number',
    AppendSyncedSaleNote = '[SaleNote] Append Synced Sale Note',
    SaleNotesFetched = '[SaleNote] Sale Notes Fetched',
    SaveSaleNotesFetched = '[SaleNote] Save Sale Notes Fetched',
    LoadSaleNotesFetched = '[SaleNote] Load Sale Notes Fetched',
    LoadSyncSaleNoteLog = '[SaleNote] Load Sync Sale Note Log',
    LoadSyncSaleNoteLogSuccess = '[SaleNote] Load Sync Sale Note Log Success',
}

export class LoadSaleNotes implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotes
    constructor(public payload?: { data: { saleNotesDateRange?: string, agentCode?: string } }) { }
}

export class SelectSaleNote implements Action {
    readonly type = SaleNoteActionTypes.SelectSaleNote
    constructor(public payload: { data: ISaleNote }) { }
}

export class NewSaleNote implements Action {
    readonly type = SaleNoteActionTypes.NewSaleNote
}

export class LoadSaleNotesSuccess implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotesSuccess
    constructor(public payload: { data: ISaleNote[] }) { }
}

export class LoadSaleNotesFailure implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotesFailure
    constructor(public payload: { error: any }) { }
}

export class LoadSaleNotesFromPouch implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotesFromPouch
    constructor() { }
}

export class LoadSaleNotesFromPouchSuccess implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotesFromPouchSuccess
    constructor(public payload: { data: ISaleNote[] }) { }
}

export class LoadSaleNotesFromPouchFailure implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotesFromPouchFailure
    constructor(public payload: { error: any }) { }
}

export class ValidateSaleNote implements Action {
    readonly type = SaleNoteActionTypes.ValidateSaleNote
    constructor(public payload: { data: ISaleNote, validationOptions?: ICustomizations }) { }
}

export class DeleteSaleNote implements Action {
    readonly type = SaleNoteActionTypes.DeleteSaleNote
    constructor(public payload: { data: ISaleNote }) { }
}

export class IncrementSaleNoteNumber implements Action {
    readonly type = SaleNoteActionTypes.IncrementSaleNoteNumber
    constructor(public payload: { data: string }) { }
}

export class LoadNextSaleNoteNumber implements Action {
    readonly type = SaleNoteActionTypes.LoadNextSaleNoteNumber
    constructor(public payload?: { data: { force: boolean } }) { }
}

export class AddLot implements Action {
    readonly type = SaleNoteActionTypes.AddLot
    constructor(public payload: { data: ILot }) { }
}

export class LoadSelectedSaleNote implements Action {
    readonly type = SaleNoteActionTypes.LoadSelectedSaleNote
}

export class LoadSelectedSaleNoteSuccess implements Action {
    readonly type = SaleNoteActionTypes.LoadSelectedSaleNoteSuccess
    constructor(public payload: { data: ISaleNote }) { }
}

export class ResetValidation implements Action {
    readonly type = SaleNoteActionTypes.ResetValidation
}

export class AppendSyncedSaleNote implements Action {
    readonly type = SaleNoteActionTypes.AppendSyncedSaleNote
    constructor(public payload: { data: ISaleNote }) { }
}

export class SaleNotesFetched implements Action {
    readonly type = SaleNoteActionTypes.SaleNotesFetched
    constructor(public payload: { data: string }) { }
}

export class SaveSaleNotesFetched implements Action {
    readonly type = SaleNoteActionTypes.SaveSaleNotesFetched
    constructor(public payload: { data: string }) { }
}

export class LoadSaleNotesFetched implements Action {
    readonly type = SaleNoteActionTypes.LoadSaleNotesFetched
    constructor() { }
}

export class LoadSyncSaleNoteLog implements Action {
    readonly type = SaleNoteActionTypes.LoadSyncSaleNoteLog
}

export class LoadSyncSaleNoteLogSuccess implements Action {
    readonly type = SaleNoteActionTypes.LoadSyncSaleNoteLogSuccess
    constructor(public payload: { data: { [index: string]: string } }) { }
}

export type SaleNoteActions =
    | LoadSaleNotes
    | LoadSaleNotesSuccess
    | LoadSaleNotesFailure
    | LoadSaleNotesFromPouch
    | LoadSaleNotesFromPouchSuccess
    | LoadSaleNotesFromPouchFailure
    | SelectSaleNote
    | NewSaleNote
    | ValidateSaleNote
    | DeleteSaleNote
    | IncrementSaleNoteNumber
    | AddLot
    | LoadSelectedSaleNote
    | LoadSelectedSaleNoteSuccess
    | ResetValidation
    | LoadNextSaleNoteNumber
    | AppendSyncedSaleNote
    | SaleNotesFetched
    | SaveSaleNotesFetched
    | LoadSaleNotesFetched
    | LoadSyncSaleNoteLog
    | LoadSyncSaleNoteLogSuccess
