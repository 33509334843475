import { Action } from '@ngrx/store'

export enum NetworkActionTypes {
    Online = '[Network] Online',
    Offline = '[Network] Offline',
    SetHost = '[Network] Set Host'
}

export class Online implements Action {
    readonly type = NetworkActionTypes.Online
}

export class Offline implements Action {
    readonly type = NetworkActionTypes.Offline
}

export class SetHost implements Action {
    readonly type = NetworkActionTypes.SetHost
    constructor(public payload: { data: string }) {}
}

export type NetworkActions = Online | Offline | SetHost
