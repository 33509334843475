// shimGlobal()
import 'whatwg-fetch'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

// import soap from 'src/soap'

// soap.createClient(
//     'https://dev1.saleg8.net/csp/mmmprod/DEV.EAL.Test.cls?WSDL=1',
//     (error, client) => {
//         if (error) {
//             console.error(error)
//             return
//         }
//         console.log('CLIENT')
//         console.log(client)

//         client.Echo(
//             { pstrJSON: JSON.stringify({ key: 'value' }) },
//             console.log.bind(console)
//         )
//     }
// )

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
    enableProdMode()
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err))
