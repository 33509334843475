import { ISaleNote, ISaleNotePdfs, IAppState, ICustomLabels, ICustomizations, ICustomization, ISyncSaleNoteResponse } from './types'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { bindCallback, zip, Observable } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { blank } from './constants'
import { toDataURL } from './app/utilities'
import { SyncSaleNoteSuccess } from './app/actions/sync-sale-note.actions'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export function renderPdfs(
    saleNote: ISaleNote,
    base64Logo?: string,
    appState?: IAppState,
    // previousSaleNote?: ISaleNote,
    syncSaleNoteResponse?: SyncSaleNoteSuccess
): Observable<ISaleNotePdfs> {
    const logo = bindCallback(toDataURL)('/assets/Mandlebrot-cropped.png')
    let {
        loginResponse: { customLabels, customizations },
    } = appState

    let amended = false
    let previousSaleNote // = syncSaleNoteResponse.payload.data.previousData
    if (typeof syncSaleNoteResponse === 'undefined') {
        previousSaleNote = saleNote
    } else {
        previousSaleNote = syncSaleNoteResponse.payload.data.previousData
        try {
            if (syncSaleNoteResponse.payload.data.data.saleNoteDetailsForm.serverStatus.message.includes('exists')) {
                amended = true
            }
        } catch (error) {

        }

    }


    if (typeof customLabels === 'undefined') {
        customLabels = {}
    }

    if (typeof customizations === 'undefined') {
        customizations = {}
    }

    let saleNoteCustomizations: ICustomizations = {}
    if (typeof customizations['Enter Sale Note'] === 'object') {
        saleNoteCustomizations = customizations['Enter Sale Note']
    }
    // ablmod230517 
    let fullDetailPDF = (appState.agentSettings.settingsForm.fullDetailPDF === '1') ? true : false
    // ablmod230907 Take param from saleG8 to see if commission can be overriden or not
    let strictOverrides = (appState.agentSettings.settingsForm.strictOverrides === '1') ? true : false

    const observable = logo.pipe(
        switchMap((logoBase64String) => {
            if (base64Logo) {
                logoBase64String = base64Logo
            }

            if (typeof previousSaleNote === 'undefined') {
                previousSaleNote = saleNote
            }

            const administratorPdf = render(
                saleNote,
                'administrator',
                logoBase64String,
                customLabels,
                saleNoteCustomizations,
                previousSaleNote,
                amended, // ablmod230907 Added comma
                fullDetailPDF, // ablmod230907 Added to make consistent
                strictOverrides // ablmod230907 Added strictOverrides param
            )
            const vendorPdf = render(
                saleNote,
                'vendor',
                logoBase64String,
                customLabels,
                saleNoteCustomizations,
                previousSaleNote,
                amended,
                fullDetailPDF, // ablmod230517
                strictOverrides // ablmod230907 Adding for commission overrides
            )
            const purchaserPdf = render(
                saleNote,
                'purchaser',
                logoBase64String,
                customLabels,
                saleNoteCustomizations,
                previousSaleNote,
                amended,
                fullDetailPDF ,// ablmod230517
                strictOverrides // ablmod230907 Adding for commission overrides
            )
            const pdfs = [administratorPdf, vendorPdf, purchaserPdf]
            const getBase64 = (pdf) =>
                bindCallback<string>(pdf.getBase64.bind(pdf))()
            const observables = pdfs.map(getBase64)
            const mapFn = ([administrator, vendor, purchaser]) => ({
                administrator,
                vendor,
                purchaser,
            })
            return zip(...observables).pipe(map(mapFn))
        })
    )
    return observable
}

export type PdfType = 'administrator' | 'vendor' | 'purchaser'

export function render(
    saleNote: ISaleNote,
    type: PdfType,
    logo?: string,
    customLabels?: ICustomLabels,
    customizations?: ICustomizations,
    previousSaleNote?: ISaleNote,
    amended?: boolean,
    fullDetailPDF?: boolean, // ablmod230517 Adding to be able to print vendor and purchaser
    strictOverrides?: boolean // ablmod230907 Adding to see if commission overrides are allowed
) {
    const header = renderHeader(saleNote, type, logo, amended)
    const vendorSummary = renderVendorTable(saleNote, customLabels, customizations, previousSaleNote, false) // ablmod230517
    const vendorSummaryA = renderVendorTable(saleNote, customLabels, customizations, previousSaleNote, fullDetailPDF) // ablmod230517 Will show no vendor notes if fullDetail requested
    const purchaserSummary = renderPurchaserTable(saleNote, customLabels, customizations, previousSaleNote, false) // ablmod230517 
    const purchaserSummaryA = renderPurchaserTable(saleNote, customLabels, customizations, previousSaleNote, fullDetailPDF) // ablmod230517 Will show no purch notes if fullDetail requested
    const lots = renderLots(saleNote, customizations)
    const details = renderDetails(saleNote, type, strictOverrides) // ablmod230907 Adding strictOverrides as parameter
    const signatures = renderSignatures(saleNote)

    const content: any[] = [header]
 
    if (type === 'administrator') {
        content.push(vendorSummary)
        content.push(purchaserSummary)
    } else if (type === 'vendor') {
        content.push(vendorSummary)
        if (fullDetailPDF) { // ablmod230517 Adding for fullDetail PDF which will show both parties
            content.push(purchaserSummaryA) 
        }
    } else if (type === 'purchaser') {
        if (fullDetailPDF) { // ablmod230517 Adding for fullDetail PDF which will show both prties
            content.push(vendorSummaryA) 
        }        
        content.push(purchaserSummary)
    }

    content.push(lots)
    content.push(details)
    content.push(signatures)

    const pageMargins: [number, number, number, number] = [35, 15, 35, 5]

    const documentDefinition = {
        pageMargins,
        content,
        footer: function (currentPage, pageCount) {
            return currentPage.toString() + ' of ' + pageCount
        },
        styles: {
            defaultStyle: {
                fontSize: 5,
            },
            header: {
                fontSize: 16,
                bold: true,
            },
            dataTable: {
                width: '100%',
                fontSize: 10,
            },
            tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'white',
                paddingLeft: '1.0rem',
                // background: 'black'
            },
            label: {
                bold: true,
                width: '250px',
            },
        },
    }
    return pdfMake.createPdf(documentDefinition)
}

// ablmod230907 Adding strictOverrides as param
function renderDetails(saleNote: ISaleNote, type: PdfType, strictOverrides) {
    let internalNotes = ''
    let internalNotesTitle = ''
    if (type === 'administrator') {
        internalNotes = saleNote.notesForm.internalNotes
        internalNotesTitle = 'Internal notes'
    }
    const adminNotesRow = [
        { text: 'Administrator notes', style: 'label', background: '' },
        {
            text: saleNote.notesForm.administratorNotes || '',
            // colSpan: 3,
            style: '',
            background: ''
        },
        {
            text: internalNotesTitle,
            style: 'label',
            background: '',
            // colSpan: 3,
        },
        {
            text: internalNotes,
            style: '',
            background: '',
            // colSpan: 3,
        }
        // { text: 'Sale Type', style: 'label' },
        // { text: saleNote.saleNoteForm.saleType || '' }
        // '',
        // '',
    ]
    const commissionPayable = getSelect(saleNote.saleNoteForm.commissionPayable)

    // ablmod230526 Adding logic to display the commission type at the end of the commission rate to show the method of calculation
    var commissionMethod:string;
    var commissionRateTemp:string; // ablmod230907 Create a var rather than ref field
    var commissionRateTemp2: string; // ablmod230921 Need another temp string
    
    // ablmod230907 console.log(type+' strictOverrides='+strictOverrides)

    // ablmod230907 Check if the strictOverrides param is to be used, if so, then strip off the describing characters
    commissionRateTemp=saleNote.saleNoteForm.commissionRate;
    commissionRateTemp2=saleNote.saleNoteForm.commissionRate;
    commissionRateTemp2=commissionRateTemp2.replace(/[$%]/g,'');
    commissionRateTemp2=commissionRateTemp2.replace(/[a-zA-Z]/g,'');
    if (strictOverrides) {
        commissionRateTemp=commissionRateTemp2
    };
    
    commissionMethod=''; // ablmod230906 Adding init
    // ablmod230906 Adding a compare after a strip to see if there are any calc caharacters in the string, if not then display as normal
    if (saleNote.saleNoteForm.commissionRate === commissionRateTemp2) {
        if (saleNote.saleNoteForm.commissionType['per head'] === '1') {
            commissionMethod = ' ($ per head)';
        } else if (saleNote.saleNoteForm.commissionType['percent'] === '1') {
            commissionMethod = ' (%)';
        }
    }
    // ablmod230526 Added commissionMethod to the end of the commissionRate
    // ablmod230907 Using the commission rate vr rather than the field
    const commissionPayableRow = renderRow(
        'Commission Rate',
        commissionRateTemp + commissionMethod|| '',
        'Commission Payable',
        commissionPayable
    )

    const saleTypeRow = renderRow(
        'Sale Type',
        saleNote.saleNoteForm.saleType,
        '',
        ''
    )
    const rows = [
        renderRow(
            'Retention to be held',
            getSelect(saleNote.saleNoteForm.inCalfWarranty),
            'Delivery date',
            saleNote.saleNoteForm.deliveryDate || ''
        ),
        saleTypeRow,
    ]

    if (type === commissionPayable.toLowerCase()) {
        rows.push(commissionPayableRow)
    } else if (type === 'administrator') {
        rows.push(commissionPayableRow)
        rows.push(adminNotesRow)
    }

    return {
        style: 'dataTable',
        layout: 'lightHorizontalLines',
        table: {
            headerRows: 1,

            widths: [120, 120, 120, 120],
            body: [
                [
                    {
                        fillColor: '#000',
                        text: 'DETAILS',
                        style: 'tableHeader',
                        colSpan: 4,
                        alignment: 'center',
                    },
                    '',
                    '',
                    '',
                ],
                ...rows,
            ],
        },
    }
}

function stringContent(s: string) {
    return { text: s }
}

function renderLots(saleNote: ISaleNote, customizations: ICustomizations) {
    const { stockDescriptionTable } = saleNote.lotsForm
    const rows = stockDescriptionTable.map((lot, i) => {
        return [
            (i + 1).toString(),
            lot.tally.toString(),
            lot.selectedProduct || '',
            lot.price || '',
            lot.weight || '',
            lot.description || '',
            lot.commissionOverride || '',
        ].map(stringContent)
    })
    return {
        style: 'dataTable',
        layout: 'lightHorizontalLines',
        table: {
            widths: [10, 50, 50, 50, 50, 140, 80],
            headerRows: 2,
            body: [
                [
                    {
                        fillColor: '#000',
                        text: 'LOTS',
                        style: 'tableHeader',
                        colSpan: 7,
                        alignment: 'center',
                    },
                    stringContent(''),
                    stringContent(''),
                    stringContent(''),
                    stringContent(''),
                    stringContent(''),
                    stringContent(''),
                ],
                [
                    stringContent('#'),
                    stringContent(customizations?.['lotsForm.tally']?.label || 'Quantity'),
                    stringContent(customizations?.['lotsForm.product']?.label || 'Product'),
                    stringContent(customizations?.['lotsForm.price']?.label || 'Price'),
                    stringContent(customizations?.['lotsForm.weight']?.label || 'Weight'),
                    stringContent(customizations?.['lotsForm.description']?.label || 'Description'),
                    stringContent(customizations?.['lotsForm.commissionOverride']?.label || 'Commission'),
                ],
                ...rows,
            ],
        },
    }
}

function renderRow(
    label0: string,
    value0: string | [string, string],
    label1: string,
    value1: string | [string, string]
) {

    const background = ['', '']

    let a = null
    let b = null
    if (Array.isArray(value0)) {
        a = value0[0]
        b = value0[1]
        if (typeof value0[0] !== 'string') {
            value0[0] = ''
        }
        if (typeof value0[1] !== 'string') {
            value0[1] = ''
        }
        if (value0[0].includes('\n')) {
            value0[1].replace('\r', '\n')
        }

        if (value0[0] !== value0[1]) {
            console.error(value0[0], value0[1])
            background[0] = 'yellow'
        }
        value0 = value0[0]
    }

    if (Array.isArray(value1)) {
        if (typeof value1[0] !== 'string') {
            value1[0] = ''
        }
        if (typeof value1[1] !== 'string') {
            value1[1] = ''
        }
        if (value1[0].includes('\n')) {
            value1[1].replace('\r', '\n')
        }
        if (value1[0] !== value1[1]) {
            console.error(value1[0], value1[1])
            background[1] = 'yellow'
        }
        value1 = value1[0]
    }

    return [
        { text: label0, style: 'label', background: background[0] },
        // label0,
        value0 || '',
        { text: label1, style: 'label', background: background[1] },
        // label1,
        value1 || '',
    ]
}

function getSelect(entityType) {
    for (const [key, value] of Object.entries(entityType)) {
        if (value === '1') {
            try {
                return key[0].toUpperCase() + key.slice(1)
            } catch (error) {
                return 'None selected'
            }
        }
    }
    return 'None selected'
}

function renderSignatures(saleNote: ISaleNote) {
    try {
        const [width, height] = [240 / 2, 160 / 2]
        return {
            style: 'dataTable',
            layout: 'noBorders',
            table: {
                dontBreakRows: true,
                widths: [width, width, width, width + 25],
                headerRows: 1,
                body: [
                    [
                        {
                            fillColor: '#000',
                            text: 'SIGNATURES',
                            style: 'tableHeader',
                            colSpan: 4,
                            alignment: 'center',
                        },
                        stringContent(''),
                        stringContent(''),
                        stringContent(''),
                    ],
                    [
                        stringContent('Vendor signature'),
                        {
                            width,
                            height,
                            image:
                                saleNote.saleNoteForm.vendorSignature || blank,
                        },
                        stringContent('Purchaser signature'),
                        {
                            width,
                            height,
                            image:
                                saleNote.saleNoteForm.purchaserSignature ||
                                blank,
                        },
                    ],
                    [
                        'In the presence of',
                        saleNote.saleNoteForm.vendorPresenceOf,
                        'In the presence of',
                        saleNote.saleNoteForm.purchaserPresenceOf,
                    ].map(stringContent),
                    [
                        stringContent('Vendor agent signature'),
                        {
                            width,
                            height,
                            image:
                                saleNote.saleNoteForm.vendorAgentSignature ||
                                blank,
                        },
                        stringContent('Purchaser agent signature'),
                        {
                            width,
                            height,
                            image:
                                saleNote.saleNoteForm.purchaserAgentSignature ||
                                blank,
                        },
                    ],
                    [
                        'Date',
                        saleNote.saleNoteForm.vendorSignatureDate,
                        'Date',
                        saleNote.saleNoteForm.purchaserSignatureDate,
                    ].map(stringContent),
                ],
            },
        }
    } catch (error) {
        alert(error.toString())
    }
}

function getDetailsWidths() {
    return [80, 160, 80, 160]
}

function renderVendorTable(
    saleNote: ISaleNote,
    customLabels: ICustomLabels,
    customizations: ICustomizations,
    previousSaleNote?: ISaleNote,
    fullDetailPDF?: boolean // ablmod230517
) {
    const widths = getDetailsWidths()
    return {
        style: 'dataTable',
        table: {
            headerRows: 1,
            widths,
            body: [
                [
                    {
                        fillColor: '#000',
                        text: 'VENDOR',
                        style: 'tableHeader',
                        colSpan: 4,
                        alignment: 'center',
                    },
                    '',
                    '',
                    '',
                ],
                renderRow(
                    customizations?.['vendorForm.entityName']?.label || 'Entity Name',
                    [saleNote.vendorForm.entityName, previousSaleNote.vendorForm.entityName],
                    customizations?.['vendorForm.entityType']?.label || 'Entity Type',
                    [getSelect(saleNote.vendorForm.entityType), getSelect(previousSaleNote.vendorForm.entityType)]
                ),
                renderRow(
                    customizations?.['vendorForm.farmSourceLivestockAccount']?.label || 'Account',
                    [saleNote.vendorForm.farmSourceLivestockAccount, previousSaleNote.vendorForm.farmSourceLivestockAccount],
                    customizations?.['vendorForm.phoneNumber']?.label || 'Phone Number',
                    [saleNote.vendorForm.phoneNumber, previousSaleNote.vendorForm.phoneNumber],
                ),
                renderRow(
                    customizations?.['vendorForm.mobileNumber']?.label || 'Mobile Number',
                    [saleNote.vendorForm.mobileNumber, previousSaleNote.vendorForm.mobileNumber],
                    customizations?.['vendorForm.rebateRate']?.label || 'Rebate Rate',
                    [saleNote.vendorForm.rebateRate, previousSaleNote.vendorForm.rebateRate],
                ),
                renderRow(
                    customizations?.['vendorForm.agentName']?.label || 'Agent Name',
                    [saleNote.vendorForm.agentName, previousSaleNote.vendorForm.agentName],
                    customizations?.['vendorForm.dateOfBirth']?.hidden ? '' : customizations?.['vendorForm.dateOfBirth']?.label || 'Date Of Birth',
                    [saleNote.vendorForm.dateOfBirth, previousSaleNote.vendorForm.dateOfBirth],
                ),
                renderRow(
                    customizations?.['vendorForm.postalAddress']?.label || 'Postal Address',
                    [saleNote.vendorForm.postalAddress, previousSaleNote.vendorForm.postalAddress],
                    customizations?.['vendorForm.emailAddress']?.label || 'Email',
                    [saleNote.vendorForm.emailAddress, previousSaleNote.vendorForm.emailAddress],
                ),
                renderRow(
                    customizations?.['vendorForm.GSTNumber']?.label || 'GST Number',
                    [saleNote.vendorForm.GSTNumber, previousSaleNote.vendorForm.GSTNumber],
                    customLabels['vendorForm.NAITNumber'] || customizations?.['vendorForm.NAITNumber']?.label || 'PIC',
                    [saleNote.vendorForm.NAITNumber, previousSaleNote.vendorForm.NAITNumber],
                ),
                renderRow(
                    customizations?.['vendorForm.thirdParty']?.label || 'Third Party',
                    [getSelect(saleNote.vendorForm.thirdParty), getSelect(previousSaleNote.vendorForm.thirdParty)],
                    customizations?.['vendorForm.security']?.label || 'Security',
                    [getSelect(saleNote.vendorForm.security), getSelect(previousSaleNote.vendorForm.security)]
                ),
                renderRow(
                    customizations?.['vendorForm.herdPTPTCode']?.label || 'Vendor Codes',
                    [saleNote.vendorForm.herdPTPTCode, previousSaleNote.vendorForm.herdPTPTCode],
                    customizations?.['purchaserForm.NAITNumber']?.options.includes('/novpdf') ? '' : customLabels['purchaserForm.NAITNumber'] || customizations?.['purchaserForm.NAITNumber']?.label || 'Purchaser PIC',
                    [customizations?.['purchaserForm.NAITNumber']?.options.includes('/novpdf') ? '' : saleNote.purchaserForm.NAITNumber, previousSaleNote.purchaserForm.NAITNumber],
                ),
                renderRow(
                    customizations?.['purchaserForm.herdPTPTCode']?.label || 'Purchaser Codes',
                    [saleNote.purchaserForm.herdPTPTCode, previousSaleNote.purchaserForm.herdPTPTCode],
                    customizations?.['notesForm.vendorNotes']?.label || 'Vendor Notes',
                    [(fullDetailPDF) ? '' : saleNote.notesForm.vendorNotes, (fullDetailPDF) ? '' : previousSaleNote.notesForm.vendorNotes] // ablmod230517
                ),
            ],
        },
        layout: 'lightHorizontalLines',
    }
}

function renderPurchaserTable(
    saleNote: ISaleNote,
    customLabels: ICustomLabels,
    customizations: ICustomizations,
    previousSaleNote?: ISaleNote,
    fullDetailPDF?: boolean // ablmod230517
) {
    const widths = getDetailsWidths()
    return {
        style: 'dataTable',
        table: {
            layout: 'lightHorizontalLines',
            headerRows: 1,
            // widths: [120, 120, 120, 120],
            widths,
            body: [
                [
                    {
                        fillColor: '#000',
                        text: 'PURCHASER',
                        style: 'tableHeader',
                        colSpan: 4,
                        alignment: 'center',
                    },
                    '',
                    '',
                    '',
                ],
                renderRow(
                    customizations?.['purchaserForm.entityName']?.label || 'Entity Name',
                    [saleNote.purchaserForm.entityName, previousSaleNote.purchaserForm.entityName],
                    customizations?.['purchaserForm.entityType']?.label || 'Entity Type',
                    [getSelect(saleNote.purchaserForm.entityType), getSelect(previousSaleNote.purchaserForm.entityType)]
                ),
                renderRow(
                    customizations?.['purchaserForm.farmSourceLivestockAccount']?.label || 'Account',
                    [saleNote.purchaserForm.farmSourceLivestockAccount, previousSaleNote.purchaserForm.farmSourceLivestockAccount],
                    customizations?.['purchaserForm.phoneNumber']?.label || 'Phone Number',
                    [saleNote.purchaserForm.phoneNumber, previousSaleNote.purchaserForm.phoneNumber],
                ),
                renderRow(
                    customizations?.['purchaserForm.mobileNumber']?.label || 'Mobile Number',
                    [saleNote.purchaserForm.mobileNumber, previousSaleNote.purchaserForm.mobileNumber],
                    customizations?.['purchaserForm.rebateRate']?.label || 'Rebate Rate',
                    [saleNote.purchaserForm.rebateRate, previousSaleNote.purchaserForm.rebateRate],
                ),
                renderRow(
                    customizations?.['purchaserForm.agentName']?.label || 'Agent Name',
                    [saleNote.purchaserForm.agentName, previousSaleNote.purchaserForm.agentName],
                    customizations?.['purchaserForm.dateOfBirth']?.hidden ? '' : customizations?.['purchaserForm.dateOfBirth']?.label || 'Date Of Birth',
                    [saleNote.purchaserForm.dateOfBirth, previousSaleNote.purchaserForm.dateOfBirth]
                ),
                renderRow(
                    customizations?.['purchaserForm.postalAddress']?.label || 'Postal Address',
                    [saleNote.purchaserForm.postalAddress, previousSaleNote.purchaserForm.postalAddress],
                    customizations?.['purchaserForm.emailAddress']?.label || 'Email',
                    [saleNote.purchaserForm.emailAddress, previousSaleNote.purchaserForm.emailAddress],
                ),
                renderRow(
                    customizations?.['purchaserForm.GSTNumber']?.label || 'GST Number',
                    [saleNote.purchaserForm.GSTNumber, previousSaleNote.purchaserForm.GSTNumber],
                    customLabels['purchaserForm.NAITNumber'] || customizations?.['purchaserForm.NAITNumber']?.label || 'PIC',
                    [saleNote.purchaserForm.NAITNumber, previousSaleNote.purchaserForm.NAITNumber],
                ),
                renderRow(
                    customizations?.['purchaserForm.herdPTPTCode']?.label || 'Purchaser Codes',
                    [saleNote.purchaserForm.herdPTPTCode, previousSaleNote.purchaserForm.herdPTPTCode],
                    customizations?.['notesForm.purchaserNotes']?.label || 'Purchaser Notes',
                    [(fullDetailPDF) ? '' : saleNote.notesForm.purchaserNotes, (fullDetailPDF) ? '' : previousSaleNote.notesForm.purchaserNotes], // ablmod230517
                ),
            ],
        },
        layout: 'lightHorizontalLines',
    }
}

function renderHeader(saleNote: ISaleNote, type: PdfType, logo: string, amended: boolean) {
    let headerText = 'SALE NOTE'

    if (type === 'vendor') {
        headerText = 'VENDOR SUMMARY'
    } else if (type === 'purchaser') {
        headerText = 'PURCHASER SUMMARY'
    }

    if (saleNote.saleNoteForm.saleType) {
        headerText = headerText + ' - ' + saleNote.saleNoteForm.saleType
    } else {
        headerText = headerText + ' - Private Sale'
    }

    let amendedText = ''
    if (amended || saleNote.workflowForm.sentToServer.complete === '1') {
        //console.log("ablmod230914 sent to server "+saleNote.workflowForm.sentToServer.complete)
        amendedText = ' (AMENDED)'
    }

    return {
        columns: [
            // { width: '50%', content: ['LOGO'] },
            { image: logo, width: 80 },
            [
                {
                    text: headerText,
                    style: 'header',
                },
                {
                    text: `Date: ${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`,
                },
                {
                    columns: [
                        { style: 'label', text: 'SALE NOTE NUMBER' },
                        saleNote.saleNoteDetailsForm.saleNoteNumber + amendedText,

                    ],
                },
            ],
            // {
            //     width: '50%',
            //     text: 'SALE NOTE',
            //     style: 'header'
            // }
        ],
    }
}
