import { Action } from '@ngrx/store'

export enum SaleNotesDateRangeActionTypes {
    GetSaleNotesDateRange = '[SaleNotesDateRange] Get SaleNotes Date Range',
    SetSaleNotesDateRange = '[SaleNotesDateRange] Set SaleNotes Date Range',
    LoadSaleNotesDateRange = '[SaleNotesDateRange] Load SaleNotes Date Range'
}

export class GetSaleNotesDateRange implements Action {
    readonly type = SaleNotesDateRangeActionTypes.GetSaleNotesDateRange
}

export class SetSaleNotesDateRange implements Action {
    readonly type = SaleNotesDateRangeActionTypes.SetSaleNotesDateRange
    constructor(public payload: { data: { saleNotesDateRange: string } }) {}
}

export class LoadSaleNotesDateRange implements Action {
    readonly type = SaleNotesDateRangeActionTypes.LoadSaleNotesDateRange
    constructor(public payload: { data: { saleNotesDateRange: string } }) {}
}

export type SaleNotesDateRangeActions =
    | GetSaleNotesDateRange
    | SetSaleNotesDateRange
    | LoadSaleNotesDateRange
