import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/types';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { ClockCheckerService } from './clock-checker.service';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService {

    private clock: string = '';
    private hasClockError = false;

    constructor(
        private clockChecker: ClockCheckerService,
        private store: Store<IAppState>
    ) { 
        // Subscribe to store and update clock instance variable when changed
        this.store.select('loginResponse').subscribe((loginResponse) => {
            this.clock = !!loginResponse?.pstrClock ? loginResponse.pstrClock : '';
            //console.log(this.clock);
        });
    }

    //Interceptor will catch any API calls made by app here
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const newRequest = request.clone({
            headers: request.headers,
            body: { ...request.body, pstrClock: this.clock }
        })

        return next.handle(newRequest).pipe(
            tap(
                (resp: HttpResponse<any>) => {
                    const body = resp?.body;
                    if (body && !!body?.Error) {
                        this.hasClockError = true;
                        this.clockChecker.setClockError();
                    }
                },
                (error: any) => {
                    console.log('API error: ', error);
                }
            ),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && this.hasClockError) {
                    console.error('Clock error found at API call: ', event.url);
                    throw new HttpErrorResponse({
                        error: 'Clock error',
                        headers: event.headers,
                        status: 500,
                        statusText: 'Authentication clock error',
                        url: event.url
                    });
                }
                return event;
            })
        );
    }
}
