import { Injectable } from '@angular/core'
import { Actions, Effect, ofType, act } from '@ngrx/effects'
import { ApiService } from 'src/app/services/api.service'
import { DbService } from './services/db.service'
import {
    map,
    catchError,
    switchMap,
    withLatestFrom,
    mergeMap,
} from 'rxjs/operators'
import { of, EMPTY, concat, from } from 'rxjs'
import {
    AgentSettingsActionTypes,
    LoadAgentSettingsSuccess,
    LoadAgentSettingsFailure,
    LoadAgentSettingsFromPouchSuccess,
    LoadAgentSettings,
    SyncAgentSettings,
    LoadAgentSettingsFromPouch,
} from './actions/agent-setting.actions'
import { ActionsUnion, saleNoteEmpty } from './reducers'
import {
    AgentDataActionTypes,
    LoadAgentDataSuccess,
    LoadAgentDataFailure,
    LoadAgentDataFromPouchFailure,
    LoadAgentData,
    LoadUpdateAgentData,
    ClearAgentDataFromPouch,
    LoadAgentDataFromPouch,
} from './actions/agent-data.actions'
import {
    SaleNoteActionTypes,
    LoadSaleNotesFromPouchSuccess,
    LoadSaleNotesFromPouchFailure,
    LoadSaleNotes,
    LoadSaleNotesFailure,
    LoadSaleNotesSuccess,
    SelectSaleNote,
    IncrementSaleNoteNumber,
    LoadSelectedSaleNoteSuccess,
    LoadSaleNotesFromPouch,
    ValidateSaleNote,
    AppendSyncedSaleNote,
    SaleNotesFetched,
    SaveSaleNotesFetched,
    LoadSyncSaleNoteLog,
    LoadSaleNotesFetched,
    NewSaleNote,
} from './actions/sale-note.actions'
import { Store } from '@ngrx/store'
import { IAppState, ISaleNote } from 'src/types'
import { docNotFound, clone, hash, hasAdminPage, obfuscatedPw } from './utilities'
import {
    SaleNotesDateRangeActionTypes,
    LoadSaleNotesDateRange,
    GetSaleNotesDateRange,
} from './actions/sale-notes-date-range.actions'
import {
    SyncSaleNoteActionTypes,
    SyncSaleNoteSuccess,
    SyncSaleNoteFailure,
    AppendSaleNote,
    SyncSaleNote,
} from './actions/sync-sale-note.actions'
import { LoadToastMessage } from './actions/toast-message.actions'
import {
    PdfActionTypes,
    LoadPdfSuccess,
    LoadPdfFailure,
    EmailPdf,
    LoadPdf,
    SetLogo,
} from './actions/pdf.actions'
import * as pdf from 'src/pdf'
import { ClientActionTypes } from './actions/client.actions'
import {
    UserActionTypes,
    LoginSuccess,
    LoginFailure,
} from './actions/user.actions'
import { Router } from '@angular/router'
import { NetworkActionTypes } from './actions/network.actions'
import { LocalStorageService, SessionStorageService } from 'angular-web-storage'
import {
    ActiveSaleActionTypes,
    LoadActiveSalesSuccess,
    LoadActiveSalesFailure,
    LoadLotsSuccess,
    LoadLotsFailure,
    SaveLotsResult,
    GetRelatedDataSuccess,
    LoadLots,
} from './actions/active-sale.actions'

function getHighestSaleNoteNumber(saleNotes: ISaleNote[], state: IAppState) {
    if (saleNotes.length === 0) {
        if (!state.agentSettings.settingsForm.nextSaleNoteNumber) {
            return `SN-${state.agentSettings.settingsForm.agentCode}-0`
        }
        return state.agentSettings.settingsForm.nextSaleNoteNumber
    }

    const sortedSaleNotes = [...saleNotes]
    sortedSaleNotes.sort((a, b) => {
        const aSaleNoteNumber = a.saleNoteDetailsForm.saleNoteNumber
        const bSaleNoteNumber = b.saleNoteDetailsForm.saleNoteNumber

        if (aSaleNoteNumber.includes('-') && bSaleNoteNumber.includes('-')) {
            const splitA = aSaleNoteNumber.split('-')
            const splitB = bSaleNoteNumber.split('-')

            const aIndex = splitA[splitA.length - 1]
            const bIndex = splitB[splitB.length - 1]

            if (parseInt(aIndex, 10) > parseInt(bIndex, 10)) {
                return 1
            } else {
                return -1
            }
        }

        return 0
    })

    return sortedSaleNotes.pop().saleNoteDetailsForm.saleNoteNumber
}

function incrementSaleNoteNumber(saleNoteNumber: string, state?: IAppState) {
    const [, , saleNumber] = saleNoteNumber.split('-')
    let [, agentCode] = saleNoteNumber.split('-')
    const nextSaleNoteNumber = parseInt(saleNumber, 10) + 1
    if (state) {
        agentCode = state.agentSettings.settingsForm.agentCode
    }
    return 'SN-' + agentCode + '-' + nextSaleNoteNumber
}

const okCloseButton = [
    {
        text: 'OK',
        role: 'cancel',
        handler: () => { },
    },
]
@Injectable()
export class AppEffects {
    constructor(
        private actions$: Actions<ActionsUnion>,
        private apiService: ApiService,
        private dbService: DbService,
        private store: Store<IAppState>,
        private router: Router,
        private local: LocalStorageService,
        private session: SessionStorageService
    ) { }

    @Effect()
    loadSavedLogo = this.actions$.pipe(
        ofType(PdfActionTypes.LoadSavedLogo),
        switchMap(() =>
            this.dbService.getLogo().pipe(
                map((logo) => {
                    if (logo !== 'data:text/plain;base64,Tm90IEZvdW5k') {
                        return new SetLogo({ logo })
                    } else {
                        return new SetLogo({ logo: '' })
                    }
                })
            )
        )
    )

    @Effect()
    saveLogo = this.actions$.pipe(
        ofType(PdfActionTypes.LoadLogo),
        switchMap((action) => {
            return this.dbService.saveLogo(action.payload.logo).pipe(
                map((logo) => {
                    if (logo !== 'data:text/plain;base64,Tm90IEZvdW5k') {
                        return new SetLogo({ logo })
                    } else {
                        return new SetLogo({ logo: '' })
                    }
                })
            )
        })
    )

    @Effect()
    getRelatedData = this.actions$.pipe(
        ofType(ActiveSaleActionTypes.GetRelatedData),
        withLatestFrom(this.store),
        switchMap(([action, state]) => {
            const pstrSaleID = action.payload.data.pstrSaleID
            const CID = state.agentSettings.settingsForm.CID
            return this.apiService
                .getRelatedData({ pstrSaleID, CID })
                .pipe(
                    map((result) => new GetRelatedDataSuccess({ data: result }))
                )
        })
    )

    @Effect()
    saveLots$ = this.actions$.pipe(
        ofType(ActiveSaleActionTypes.SaveLots),
        withLatestFrom(this.store),
        switchMap(([a, b]) => {
            console.error(a.payload.data.Lots)
            return this.apiService
                .saveLots({
                    CID: b.agentSettings.settingsForm.CID,
                    Lots: a.payload.data.Lots,
                    pstrSaleID: a.payload.data.pstrSaleID,
                    pstrAgentCode: b.agentSettings.settingsForm.agentCode
                })
                .pipe(
                    map((result) => {
                        return new SaveLotsResult({
                            data: {
                                ...result,
                                pstrSaleID: a.payload.data.pstrSaleID,
                            },
                            toastMessage: a.payload.toastMessage
                        })
                    })
                )
        })
    )

    @Effect()
    saveLotsSuccess = this.actions$.pipe(
        ofType(ActiveSaleActionTypes.SaveLotsResult),
        switchMap((action) => {
            if (action.payload.data.result === 'OK') {
                const actions: any[] = [
                    new LoadToastMessage({
                        data: {
                            message: action.payload.toastMessage || 'Saved lot successfully',
                            color: 'success',
                            buttons: okCloseButton,
                            duration: 1000,
                        },
                    }),
                ]
                if (Array.isArray(action.payload.data.Lots)) {
                    const updateLotAction = new LoadLotsSuccess({
                        data: {
                            response: { Lots: action.payload.data.Lots },
                            saleID: action.payload.data.pstrSaleID,
                        },
                    })
                    actions.push(updateLotAction)
                } else {
                    actions.push(new LoadLots({ data: { saleID: action.payload.data.pstrSaleID } }))
                }
                return actions
            } else {
                return [
                    new LoadToastMessage({
                        data: {
                            message: 'Failed to save lot',
                            color: 'danger',
                            buttons: okCloseButton,
                            duration: 1000,
                        },
                    }),
                ]
            }
        })
    )

    @Effect()
    getLots$ = this.actions$.pipe(
        ofType(ActiveSaleActionTypes.LoadLots),
        withLatestFrom(this.store),
        switchMap(([action, state]) =>
            this.apiService
                .getLots({
                    pstrSaleID: action.payload.data.saleID,
                    CID: state.agentSettings.settingsForm.CID,
                })
                .pipe(
                    map((response) => {
                        const lots = response.Lots
                        return new LoadLotsSuccess({
                            data: {
                                response,
                                saleID: action.payload.data.saleID,
                            },
                        })
                    }),
                    catchError((error) => of(new LoadLotsFailure({ error })))
                )
        )
    )

    @Effect()
    getActiveSales$ = this.actions$.pipe(
        ofType(ActiveSaleActionTypes.LoadActiveSales),
        withLatestFrom(this.store),
        switchMap(([action, state]) =>
            this.apiService
                .getActiveSales({
                    CID: state.agentSettings.settingsForm.CID,
                })
                .pipe(
                    map(
                        (activeSales) =>
                            new LoadActiveSalesSuccess({ data: activeSales })
                    ),
                    catchError((error) =>
                        of(new LoadActiveSalesFailure({ error }))
                    )
                )
        )
    )

    @Effect({ dispatch: true })
    setHost$ = this.actions$.pipe(
        ofType(NetworkActionTypes.SetHost),
        map(({ payload: { data } }) => {
            this.apiService.setHost(data)
            return new LoadToastMessage({
                data: {
                    message: 'Set host to ' + data,
                    duration: 4096,
                    color: 'tertiary',
                    buttons: okCloseButton,
                },
            })
        })
    )

    @Effect({ dispatch: true })
    loginSuccess$ = this.actions$.pipe(
        ofType(UserActionTypes.LoginSuccess),
        withLatestFrom(this.store),
        switchMap(([a, state]) => {
            const { CID, agentCode: pstrAgentCode } = a.payload.data
            const fetchSaleNotes = a.payload.fetchSaleNotes
            const newSettings = clone(state.agentSettings)

            if (a.payload.data.redirect) {
                const lastUrl = this.local.get('lastUrl')

                if (typeof lastUrl === 'string') {
                    if (lastUrl.includes('login')) {
                        this.local.set('lastUrl', '/home')
                        this.router.navigate(['home'])
                    } else {
                        this.router.navigate(lastUrl.slice(1).split('/'))
                    }
                } else {
                    this.router.navigate(['home'])
                }
            }

            newSettings.settingsForm.CID = CID
            newSettings.settingsForm.agentCode = pstrAgentCode
            // ablmod231017 Logic of interest - need to do an get agent data on signin
            return this.apiService
                .getAgentSettings({ CID, pstrAgentCode })
                .pipe(
                    switchMap((settings) => {
                        if (a?.payload?.data?.response?.LastSaleNote) {
                            const LastSaleNote = a.payload.data.response.LastSaleNote
                            //console.error('Setting last sale note', LastSaleNote) // ablmod230422 Corrected comment
                            settings.settingsForm.nextSaleNoteNumber = LastSaleNote
                        }
                        const actions: any[] = [
                            new LoadAgentSettingsSuccess({ data: settings }),
                            new LoadUpdateAgentData()
                        ]
                        if (fetchSaleNotes) {
                            actions.push(new LoadSaleNotesFetched())
                        }
                        if (state.saleNotesFetched === null) {
                            actions.push(new LoadSaleNotes())
                        }
                        
                        return actions
                    }),
                    catchError((error) =>
                        of(
                            new LoadAgentSettingsFailure({
                                error: clone(error),
                            })
                        )
                    )
                )

            // return of(new SyncAgentSettings({ data: newSettings }))
        })
    )

    @Effect()
    loadUpdateAgentData = this.actions$.pipe(
        ofType(AgentDataActionTypes.LoadUpdateAgentData),
        withLatestFrom(this.store),
        switchMap(([a, store]) => {
            const agentCode = store.agentSettings.settingsForm.agentCode
            return this.dbService.getAgentData().pipe(
                map((data) => {
                    this.store.dispatch(new LoadAgentDataSuccess({ data }))
                    //console.log('AD loaded from Pouch')
                    return new LoadAgentData({ data: { pstrFullRefresh: false, agentCode: agentCode } })
                }),
                catchError((error) => {
                    error = clone(error)
                    return of(new LoadAgentDataFromPouchFailure({ error }))
                })
            )
        })
    )

    @Effect()
    loginFailure$ = this.actions$.pipe(
        ofType(UserActionTypes.LoginFailure),
        map((value) => {
            return new LoadToastMessage({
                data: {
                    message: 'Incorrect username or password',
                    buttons: okCloseButton,
                    duration: 2048,
                    color: 'danger',
                },
            })
        })
    )

    @Effect()
    login$ = this.actions$.pipe(
        ofType(UserActionTypes.Login),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const {
                CID,
                agentCode: pstrUserID,
                password: pstrPassword,
                redirect,
            } = action.payload.data

            this.local.set('agentCode', pstrUserID)
            this.local.set('CID', CID);
            this.session.set('password', obfuscatedPw(pstrPassword, true));

            const clockExpiry = new Date()
            clockExpiry.setHours(23, 59, 59, 59)

            if (!appState.online) {
                return from(hash(pstrPassword)).pipe(
                    switchMap((storedHash) => {
                        if (storedHash !== this.local.get('hash')) {
                            return of(
                                new LoginFailure({
                                    error: 'Username or password is incorrect',
                                })
                            )
                        } else {
                            return this.dbService.getLoginResponse().pipe(
                                map((doc) => {
                                    return new LoginSuccess({
                                        fetchSaleNotes: false,
                                        data: {
                                            redirect,
                                            CID,
                                            agentCode: pstrUserID,
                                            loggedIn: true,
                                            clockExpiry: doc?.pstrClock ?
                                                clockExpiry.getTime() :
                                                undefined,
                                            response: doc,
                                        },
                                    })
                                })
                            )
                        }
                    })
                )
            }

            const clearActions = []
            return this.apiService
                .login({ CID, pstrUserID, pstrPassword })
                .pipe(
                    switchMap((result) => {
                        const loginResult = result.body
                        if (loginResult.result === 'OK') {
                            hash(pstrPassword)
                                .then((hashed) => {
                                    this.local.set('hash', hashed)
                                })
                                .catch(console.error.bind(console))
                            if (loginResult.Menu) {
                                if (Array.isArray(loginResult.Menu)) {
                                    this.dbService.saveLoginResponse(
                                        loginResult
                                    )
                                }
                            }
                            return [
                                new LoginSuccess({
                                    fetchSaleNotes: true,
                                    data: {
                                        redirect,
                                        CID,
                                        agentCode: pstrUserID,
                                        loggedIn: true,
                                        clockExpiry: loginResult?.pstrClock ?
                                            clockExpiry.getTime() :
                                            undefined,
                                        response: loginResult,
                                    },
                                }),
                            ]
                        } else if (loginResult.result === 'NG') {
                            return [new LoginFailure({ error: loginResult })]
                        }
                    }),
                    catchError((error) => of(new LoginFailure({ error })))
                )
        })
    )

    @Effect()
    clearAgentDataFromPouch$ = this.actions$.pipe(
        ofType(AgentDataActionTypes.ClearAgentDataFromPouch),
        switchMap(() => {
            return []
        })
    )

    @Effect()
    validateOnLoad$ = this.actions$.pipe(
        ofType(ClientActionTypes.LoadClientIntoSaleNote),
        withLatestFrom(this.store),
        switchMap(([action, state]) => {
            const errors = state.validSaleNote

            const allValid = Object.values(errors).every((valid) => valid)
            if (allValid) {
                return []
            }

            return of(new ValidateSaleNote({ data: state.selectedSaleNote, validationOptions: state.loginResponse.customizations['Enter Sale Note'] }))
        })
    )

    @Effect()
    email$ = this.actions$.pipe(
        ofType(PdfActionTypes.EmailPdf),

        withLatestFrom(this.store),

        switchMap(([action, appState]) => {
            const { manualEmail } = action.payload.data
            // ablmod231006 This is code of interest to the pdf sending error where the appstate sn was incorrect - loading from the object
            const pstrSaleNote = action.payload.data.saleNoteNumber // ablmod231006 appState.selectedSaleNote.saleNoteDetailsForm.saleNoteNumber
            const pstrAgentCode = appState.agentSettings.settingsForm.agentCode
            const pstrSendMode = action.payload.data.mode
            const pstrBase64 = action.payload.data.pdf
            const CID = appState.agentSettings.settingsForm.CID
            const parameters = {
                pstrAgentCode,
                pstrSaleNote,
                pstrSendMode,
                CID,
            }
            const pstrFileName = `${pstrSaleNote}${pstrSendMode}.pdf`

            if (manualEmail) {
                const upload = this.apiService
                    .upload({
                        pstrAgentCode,
                        pstrBase64,
                        pstrFileName,
                        pstrSaleNote,
                        CID,
                    })
                    .pipe(
                        map(
                            () =>
                                new LoadToastMessage({
                                    data: {
                                        message: 'PDF uploaded successfully',
                                        duration: 1000,
                                        color: 'success',
                                    },
                                })
                        ),
                        catchError((error) => {
                            return of(
                                new LoadToastMessage({
                                    data: {
                                        message: 'Failed to upload pdf',
                                        duration: 2000,
                                        color: 'danger',
                                    },
                                })
                            )
                        })
                    )

                const send = this.apiService.email(parameters).pipe(
                    map(
                        () =>
                            new LoadToastMessage({
                                data: {
                                    message: 'Email sent successfully',
                                    duration: 1000,
                                    color: 'success',
                                },
                            })
                    ),
                    catchError((error) => {
                        return of(
                            new LoadToastMessage({
                                data: {
                                    message: 'Failed to send email',
                                    duration: 2000,
                                    color: 'danger',
                                },
                            })
                        )
                    })
                )

                return concat(upload, send)
            } else {
                const upload = this.apiService
                    .upload({
                        pstrAgentCode,
                        pstrBase64,
                        pstrFileName,
                        pstrSaleNote,
                        CID,
                    })
                    .pipe(
                        map(
                            (e) =>
                                new LoadToastMessage({
                                    data: {
                                        message: 'PDF uploaded successfully',
                                        duration: 1000,
                                        color: 'success',
                                    },
                                })
                        ),
                        catchError((error) => {
                            return of(
                                new LoadToastMessage({
                                    data: {
                                        message: 'Failed to upload pdf',
                                        duration: 2000,
                                        color: 'danger',
                                    },
                                })
                            )
                        })
                    )
                return upload
            }
        })
    )

    @Effect({ dispatch: false })
    selectSaleNote$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.SelectSaleNote),
        switchMap((action) => {
            //const saleNote: any = clone(action.payload.data)
            // if (!('not sent' in saleNote.workflowForm.sentToServer)) {
            //     saleNote.workflowForm.sentToServer['not sent'] = '0'
            // }
            return this.dbService.selectSaleNote(action.payload.data)
        })
    )

    @Effect()
    validateSaleNote = this.actions$.pipe(
        ofType(SaleNoteActionTypes.ValidateSaleNote),
        withLatestFrom(this.store),
        mergeMap(([, appState]) => {
            const stringMap = {
                'saleNoteForm.commissionPayable': 'commission payable',
                'saleNoteForm.commissionRate': 'commission rate',
                'saleNoteForm.commissionType': 'commission type',
                'saleNoteForm.deliveryDate': 'delivery date',
                'lotsForm.lots': 'sale note lots',
                'purchaserForm.agentCode': 'purchaser agent code',
                'purchaserForm.agentName': 'purchaser agent name',
                'purchaserForm.entityName': 'purchaser entity name',
                'purchaserForm.NAITNumber': 'purchaser PIC',
                'saleNoteDetailsForm.saleNoteNumber': 'sale note number',
                'vendorForm.agentCode': 'vendor agent code',
                'vendorForm.agentName': 'vendor agent name',
                'vendorForm.entityName': 'vendor entity name',
                'vendorForm.NAITNumber': 'vendor PIC',
            }
            const errors = appState.validSaleNote

            const allValid = Object.values(errors).every((valid) => valid)

            if (allValid) {
                return of(new LoadToastMessage({}))
            }

            const errorMessages = []

            for (const [key, valid] of Object.entries(errors)) {
                if (!valid) {
                    errorMessages.push(stringMap[key])
                }
            }

            const firstThreeMessages = errorMessages.slice(0, 3)
            const otherErrors = errorMessages.length - firstThreeMessages.length
            const message =
                `Sale note data is missing ${firstThreeMessages.join(', ')}` +
                (errorMessages.length > 3
                    ? ` and ${otherErrors} other field${otherErrors === 1 ? '' : 's'
                    }`
                    : '')

            return of(
                new LoadToastMessage({
                    data: {
                        message,
                        color: 'danger',
                        duration: 1000 * 10,
                        buttons: okCloseButton,
                    },
                })
            )
        })
    )

    @Effect()
    loadSelectedSaleNote$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSelectedSaleNote),
        switchMap(() =>
            this.dbService
                .selectSaleNote()
                .pipe(map((data) => new LoadSelectedSaleNoteSuccess({ data })))
        )
    )

    @Effect({ dispatch: false })
    deleteSaleNoteFromPouch$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.DeleteSaleNote),
        map((action) =>
            this.dbService.deleteSaleNote(action.payload.data).subscribe()
        )
    )

    @Effect({ dispatch: false })
    deleteSaleNoteFromServer$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.DeleteSaleNote),
        withLatestFrom(this.store),
        map(([action, appState]) =>
            this.apiService
                .deleteSaleNote({
                    pstrAgentCode:
                        appState.agentSettings.settingsForm.agentCode,
                    pstrSaleNote:
                        action.payload.data.saleNoteDetailsForm.saleNoteNumber,
                    CID: appState.agentSettings.settingsForm.CID,
                })
                .subscribe()
        )
    )

    @Effect()
    loadNextSaleNoteNumber = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadNextSaleNoteNumber),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const actions = []
            const currentSaleNoteNumber =
                appState.selectedSaleNote.saleNoteDetailsForm.saleNoteNumber
            const highestSaleNoteNumber = getHighestSaleNoteNumber(
                appState.saleNotes,
                appState
            )
            const nextSaleNoteNumber =
                currentSaleNoteNumber === ''
                    ? incrementSaleNoteNumber(highestSaleNoteNumber, appState)
                    : currentSaleNoteNumber

            console.error('currentSaleNoteNumber', currentSaleNoteNumber)

            if (action?.payload?.data?.force) {
                actions.push(
                    new IncrementSaleNoteNumber({ data: incrementSaleNoteNumber(highestSaleNoteNumber, appState) })
                )
            } else {
                actions.push(
                    new IncrementSaleNoteNumber({ data: nextSaleNoteNumber })
                )
            }

            if (
                appState.agentSettings.settingsForm.nextSaleNoteNumber !==
                nextSaleNoteNumber && appState.saleNotes.length > 0
            ) {
                const agentSettings = clone(appState.agentSettings)
                agentSettings.settingsForm.nextSaleNoteNumber = nextSaleNoteNumber
                const syncAgentAction = new SyncAgentSettings({ data: agentSettings })
                actions.push(syncAgentAction)
            }

            return actions
        })
    )

    @Effect()
    newSaleNote$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.NewSaleNote),
        withLatestFrom(this.store),
        map(([, appState]) => {
            const { saleNotes } = appState
            console.error(saleNotes)
            if (saleNotes.length > 0) {
                const highestSaleNoteNumber = getHighestSaleNoteNumber(
                    saleNotes,
                    appState
                )
                const nextSaleNoteNumber = incrementSaleNoteNumber(
                    highestSaleNoteNumber,
                    appState
                )
                console.error(nextSaleNoteNumber)
                return new IncrementSaleNoteNumber({ data: nextSaleNoteNumber })
            } else {
                const highestSaleNoteNumber = getHighestSaleNoteNumber(
                    saleNotes,
                    appState
                )
                const nextSaleNoteNumber = incrementSaleNoteNumber(
                    highestSaleNoteNumber,
                    appState
                )
                console.error(nextSaleNoteNumber)
                return new IncrementSaleNoteNumber({ data: nextSaleNoteNumber })
            }
        }),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const actions = []
            const data = clone(appState.selectedSaleNote)
            const agentSettings = clone(appState.agentSettings)

            data.saleNoteDetailsForm.saleNoteNumber = action.payload.data
            data.saleNoteForm.saleType = appState.saleTypes[0].Name
            actions.push(new SelectSaleNote({ data }))
            if (
                (agentSettings.settingsForm.nextSaleNoteNumber !==
                    action.payload.data) && (appState.saleNotes.length > 0)
            ) {
                agentSettings.settingsForm.nextSaleNoteNumber =
                    action.payload.data
                const syncAgentSettings = new SyncAgentSettings({
                    data: agentSettings,
                })
                actions.push(syncAgentSettings)
            }
            return actions
        })
    )

    // @Effect()
    // newSaleNoteUpdateSettings$ = this.actions$.pipe(
    //     ofType(SaleNoteActionTypes.NewSaleNote),
    //     withLatestFrom(this.store),
    //     switchMap(([, appState]) => {
    //         return EMPTY
    //     })
    // )

    @Effect()
    loadPdfs$ = this.actions$.pipe(
        ofType(PdfActionTypes.LoadPdf),
        withLatestFrom(this.store),
        switchMap(([action, appState]) =>
            pdf
                .renderPdfs(action.payload.data, appState.pdfLogo, appState)
                .pipe(
                    map((data) => new LoadPdfSuccess({ data })),
                    catchError((error) => of(new LoadPdfFailure({ error })))
                )
        )
    )

    @Effect()
    syncSaleNoteLocal$ = this.actions$.pipe(
        ofType(SyncSaleNoteActionTypes.AppendSaleNote),
        switchMap((action) => {
            return this.dbService.setSaleNote(action.payload.data).pipe(
                map(({ saleNotes }) => {
                    console.log('loading new sale notes', saleNotes)
                    return new LoadSaleNotesFromPouch()
                })
            )
        })
    )

    @Effect()
    saveSaleNotesFetched$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.SaveSaleNotesFetched),
        switchMap((action) => {
            return this.dbService.setSaleNotesFetched(action.payload.data)
        }),
        switchMap(() => {
            return this.dbService.getSaleNotesFetched().pipe(
                map((data) => {
                    return new SaleNotesFetched({ data: data.date })
                })
            )
        })
    )

    @Effect()
    loadSaleNotesFetched$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSaleNotesFetched),
        switchMap(() => {
            return this.dbService.getSaleNotesFetched().pipe(
                switchMap((data) => {
                    return [
                        new SaleNotesFetched({ data: data.date }),
                        new LoadSyncSaleNoteLog(),
                    ]
                })
            )
        })
    )

    @Effect()
    loadSyncSaleNoteLog$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSyncSaleNoteLog),
        withLatestFrom(this.store),
        switchMap(([a, appState]) => {
            const offset = new Date().getTimezoneOffset() * 60000
            const localISOTime = new Date(Date.now() - offset)
                .toISOString()
                .slice(0, 19)
                .replace('T', ' ')
            const pstrTimeStamp =
                typeof appState.saleNotesFetched === 'string'
                    ? appState.saleNotesFetched
                    : localISOTime
            return this.apiService
                .syncSaleNoteLog({
                    pstrTimeStamp,
                })
                .pipe(
                    switchMap((syncSaleNoteLog) => {
                        const { data } = syncSaleNoteLog as any
                        // Need to add 'T' back because safari does not like the space
                        const lastSynced = new Date(pstrTimeStamp.replace(' ', 'T')).valueOf()

                        if (data) {
                            for (const key in data) {
                                if (!(key in data)) {
                                    continue
                                }
                                if (lastSynced < new Date(key.replace(' ', 'T')).valueOf()) {
                                    return [new LoadSaleNotes(), new LoadToastMessage({
                                        data: {
                                            message: 'New sale notes found, syncing',
                                            duration: 2000,
                                            buttons: [
                                                {
                                                    text: 'Close',
                                                    role: 'cancel',
                                                    handler: () => { },
                                                }
                                            ]
                                        }
                                    })]
                                }
                            }
                            return [new LoadSaleNotesFromPouch()]
                        } else {
                            return [new LoadSaleNotesFromPouch()]
                        }
                    }),
                    catchError((err) => of(new LoadSaleNotesFromPouch()))
                )
        })
    )

    @Effect()
    syncSaleNote$ = this.actions$.pipe(
        ofType(SyncSaleNoteActionTypes.SyncSaleNote),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const toast = new LoadToastMessage({
                data: {
                    message:
                        'No internet connection. Sale note is saved locally but must be synced later',
                    duration: 10000,
                    color: 'warning',
                    buttons: okCloseButton,
                },
            })
            if (!appState.online) {
                const data = clone(appState.selectedSaleNote)
                data.synced = false
                return this.dbService.setSaleNote(data).pipe(
                    switchMap(() => [toast, new LoadSaleNotesFromPouch()]),
                    catchError((error) =>
                        of(
                            new LoadToastMessage({
                                data: {
                                    message:
                                        'Failed to save locally and no internet connection',
                                    duration: 10000,
                                    buttons: okCloseButton,
                                },
                            })
                        )
                    )
                )
            }

            const { saleNote } = action.payload.data
            const { agentSettings } = appState
            const agentCode = agentSettings.settingsForm.agentCode

            // TODO: NO syncing without CID and agentCode

            const syncedSaleNote = clone(saleNote)

            return this.apiService
                .syncSaleNote({ 
                    pstrAgentCode: agentCode,
                    saleNote: syncedSaleNote,
                    agentSettings
                })
                .pipe(
                    switchMap((response) => {
                        const { data } = response
                        const color =
                            data.saleNoteDetailsForm.serverStatus.status ===
                                'error'
                                ? 'danger'
                                : 'success'
                        const capitalize = (a: string) =>
                            a[0].toUpperCase() + a.slice(1)
                        let existsMessage = ''

                        if (
                            data.saleNoteDetailsForm.serverStatus.message === 'Sale Note already exists' &&
                            data.saleNoteDetailsForm.generateEmail === 'A'
                        ) {
                            existsMessage = ', sending amendment email'
                        }

                        const message = capitalize(
                            data.saleNoteDetailsForm.serverStatus.message
                        ) + existsMessage
                        const buttons = okCloseButton
                        syncedSaleNote.synced = true

                        const actions = [
                            new AppendSaleNote({ data: syncedSaleNote }),
                            new SelectSaleNote({ data: syncedSaleNote }),
                            new SyncSaleNoteSuccess({ data: response }),
                            new LoadToastMessage({
                                data: {
                                    message,
                                    duration: 2048,
                                    color,
                                    buttons,
                                },
                            }),
                        ]

                        return actions
                    }),
                    catchError((error) =>
                        of(new SyncSaleNoteFailure({ error }))
                    )
                )
        })
    )

    @Effect()
    syncSaleNoteSuccess$ = this.actions$.pipe(
        ofType(SyncSaleNoteActionTypes.SyncSaleNoteSuccess),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const {
                payload: {
                    data: { data },
                },
            } = action

            const updatedSaleNote = clone(appState.selectedSaleNote)

            if (data.workflowForm) {
                // ablmod231006 Adding comment. This next logic updates the locally stored sale note with any changes from the server.
                // The saleG8 server sends back workflow sent to server - as sent
                // ablmod230921 console.log('ablmod230921 in syncsalenote success');
                for (const key in data.workflowForm) {
                    if (key in updatedSaleNote.workflowForm) {
                        // ablmod230921 Removing log console.error(data.workflowForm)
                        updatedSaleNote.workflowForm[key] =
                            data.workflowForm[key]
                    }
                }
            }

            const newSaleNote: ISaleNote = saleNoteEmpty

            const selectSaleNote = new SelectSaleNote({ data: clone(updatedSaleNote) })

            const renderPdfs = pdf
                .renderPdfs(
                    appState.selectedSaleNote,
                    appState.pdfLogo,
                    appState,
                    action
                )
                .pipe(
                    switchMap((pdfs) => {
                        const generatePdfAction = new LoadPdf({
                            data: updatedSaleNote,
                        })
                        const emailAction = new EmailPdf({
                            data: {
                                mode: '',
                                pdf: pdfs.administrator,
                                manualEmail: false,
                                saleNoteNumber: updatedSaleNote.saleNoteDetailsForm.saleNoteNumber // ablmod231006 Adding to the object to be used for upload
                            },
                        })
                        const newSaleNoteAction = new NewSaleNote()
                        const updateSaleNote = new AppendSaleNote({ data: updatedSaleNote }) // ablmod230921 Adding to do before new sale note
                        const actions = []

                        if (data.saleNoteDetailsForm.generateEmail === 'A') {
                            actions.push(generatePdfAction, emailAction)
                        }

                        actions.push(selectSaleNote)

                        if (data.saleNoteDetailsForm.generateEmail === 'A') {
                            actions.push(updateSaleNote) // ablmod230921 Adding an update action to get the complete staus into couch
                            actions.push(newSaleNoteAction)
                        }

                        return actions
                    }),
                    catchError((error) => {
                        return of(
                            new LoadToastMessage({
                                data: {
                                    message: error.toString(),
                                    color: 'danger',
                                    buttons: okCloseButton,
                                },
                            })
                        )
                    })
                )

            return renderPdfs
        })
    )

    @Effect()
    getSaleNotesDateRange$ = this.actions$.pipe(
        ofType(SaleNotesDateRangeActionTypes.GetSaleNotesDateRange),
        switchMap((action) =>
            this.dbService
                .getSaleNotesDateRange()
                .pipe(
                    switchMap((data) => {
                        return [new LoadSaleNotesDateRange({ data })]
                    }
                    )
                )
        )
    )

    @Effect()
    setSaleNotesDateRange$ = this.actions$.pipe(
        ofType(SaleNotesDateRangeActionTypes.SetSaleNotesDateRange),
        switchMap((action) =>
            this.dbService
                .setSaleNotesDateRange(action.payload.data.saleNotesDateRange)
                .pipe(map(() => new GetSaleNotesDateRange()))
        )
    )

    @Effect()
    loadFromServerWhenNoSaleNotes$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSaleNotesFromPouchSuccess),
        switchMap((action) => {
            if (action.payload.data.length === 0) {
                return [new LoadSaleNotes()]
            }
            return []
            // return [new NewSaleNote()]
        })
    )

    @Effect()
    loadSaleNotesFromPouch$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSaleNotesFromPouch),
        withLatestFrom(this.store),
        switchMap(([, appState]) =>
            this.dbService.getSaleNotes().pipe(
                map(
                    ({ saleNotes: data }) => {
                        let filterFn = (saleNote) => {
                            const {
                                agentCode,
                            } = appState.agentSettings.settingsForm
                            return (
                                saleNote.agentCode === agentCode ||
                                saleNote.vendorForm.agentCode ===
                                agentCode ||
                                saleNote.purchaserForm.agentCode ===
                                agentCode
                            )
                        }
                        if (hasAdminPage(appState)) {
                            filterFn = () => true
                        }
                        // ablmod230403 Testing out what happens if I set this true
                        // It worked: filterFn = () => true
                        return new LoadSaleNotesFromPouchSuccess({
                            data: data.filter(filterFn),
                        })
                    }
                ),
                catchError((error) =>
                    of(new LoadSaleNotesFromPouchFailure({ error }))
                )
            )
        )
    )

    @Effect({ dispatch: false })
    saveSaleNotesToPouch$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSaleNotesSuccess),
        map((action) =>
            this.dbService.setSaleNotes(action.payload.data).pipe(
                map(() => EMPTY),
                catchError((error) => of(EMPTY))
            )
        )
    )

    @Effect()
    loadSaleNotesFromPouchFailure$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSaleNotesFromPouchFailure),
        map((action) => {
            const { error } = action.payload
            if (docNotFound(error)) {
                return new LoadSaleNotes()
            }
            return new LoadSaleNotesFailure({ error })
            // return action
        })
    )

    @Effect()
    loadSaleNotesFromServer$ = this.actions$.pipe(
        ofType(SaleNoteActionTypes.LoadSaleNotes),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const pstrDateRange = action?.payload?.data?.saleNotesDateRange
                ? action.payload.data.saleNotesDateRange
                : appState.saleNotesDateRange
            const CID = appState.agentSettings.settingsForm.CID
            const agentCode = action?.payload?.data?.agentCode
                ? action.payload.data.agentCode
                : appState.agentSettings.settingsForm.agentCode
            // const { CID, agentCode } = appState.agentSettings.settingsForm

            function isUnsyncedSaleNotes(saleNotes: ISaleNote[]): boolean {
                return !saleNotes.every((saleNote) => {
                    if (typeof saleNote.synced === 'undefined') {
                        return true
                    } else {
                        return saleNote.synced
                    }
                })
            }

            if (isUnsyncedSaleNotes(appState.saleNotes)) {
                return of(
                    new LoadToastMessage({
                        data: {
                            message:
                                'You have local changes that have not been synced yet. Please sync all sale notes first.',
                            color: 'warning',
                            buttons: okCloseButton,
                        },
                    })
                )
            }

            return this.apiService
                .getSaleNotes({ CID, pstrAgentCode: agentCode, pstrDateRange })
                .pipe(
                    switchMap((data) => {
                        if (data['error']) {
                            const error = data['error']
                            if (error === 'No sale notes found') {
                                return [new LoadSaleNotesSuccess({ data: [] })]
                            }
                            return [new LoadSaleNotesFailure({ error })]
                        }
                        const offset = new Date().getTimezoneOffset() * 60000
                        const localISOTime = new Date(Date.now() - offset)
                            .toISOString()
                            .slice(0, 19)
                            .replace('T', ' ')
                        const updateFetchedAction = new SaveSaleNotesFetched({
                            data: localISOTime,
                        })
                        let filterFn = (saleNote) => {
                            return saleNote.agentCode === agentCode
                        }
                        if (hasAdminPage(appState) &&
                            agentCode !== appState.agentSettings.settingsForm.agentCode) {
                            console.error('not filter')
                            filterFn = () => true
                        }
                        const actions = [
                            updateFetchedAction,
                            new LoadSaleNotesSuccess({
                                data: data.filter(filterFn),
                            }),
                        ]
                        return actions
                    }),
                    catchError((error) =>
                        of(new LoadSaleNotesFailure({ error }))
                    )
                )
        })
    )

    @Effect()
    loadAgentData$ = this.actions$.pipe(
        ofType(AgentDataActionTypes.LoadAgentData),
        withLatestFrom(this.store),
        switchMap(([action, appState]) => {
            const { CID, agentCode } = appState.agentSettings.settingsForm;
            const { pstrFullRefresh, agentCode: overrideAgentCode } = action.payload.data;
            return this.apiService
                .getAgentData({
                    CID,
                    pstrAgentCode: overrideAgentCode || agentCode,
                    pstrFullRefresh,
                })
                .pipe(
                    map((data) => {
                        // ablmod230510 Adding toast message as this load can take a while if there are many clients.
                        this.store.dispatch(
                            new LoadToastMessage({
                                data: {
                                    message: `Clients have been updated from saleG8.`,
                                    color: 'tertiary',
                                    duration: 1024,
                                    buttons: okCloseButton,
                                },
                            })
                        );                        
                        if (pstrFullRefresh) {
                            return new LoadAgentDataSuccess({ data });
                        }
                        // ablmod230510 Code location comment
                        // Replace current clients with any updated ones from response
                        const mergedClients = appState.clients.map(client => {
                            // Find client in response that matches a client we are iterating
                            const updatedClient = data.Client.find(findClient => findClient.Key === client.Key);
                            if (updatedClient) {
                                // Find index in response
                                const clientIndex = data.Client.findIndex(v => v.Key === updatedClient.Key);
                                // Remove it from response data
                                data.Client.splice(clientIndex, 1);
                                // Return updated client from response
                                return updatedClient;
                            } else {
                                // No client found just return current client from state
                                return client;
                            }
                        });
                        // Add any new clients as data.Client will contain any new ones that did not match
                        return new LoadAgentDataSuccess({ data: { ...data, Client: mergedClients.concat(data.Client) } });
                    }),
                    catchError((error) =>
                        of(new LoadAgentDataFailure({ error }))
                    )
                );
        })
    );

    @Effect()
    loadAgentDataFromPouch$ = this.actions$.pipe(
        ofType(AgentDataActionTypes.LoadAgentDataFromPouch),
        switchMap(() =>
            this.dbService.getAgentData().pipe(
                map((data) => new LoadAgentDataSuccess({ data })),
                catchError((error) => {
                    // Required for action serializability
                    error = clone(error)
                    return of(new LoadAgentDataFromPouchFailure({ error }))
                })
            )
        )
    )

    @Effect({ dispatch: false })
    loadAgentDataFromServerSuccess$ = this.actions$.pipe(
        ofType(AgentDataActionTypes.LoadAgentDataSuccess),
        map((action) => {
            this.dbService.saveAgentData(action.payload.data)
        })
    )

    @Effect()
    loadAgentDataFromPouchFailure$ = this.actions$.pipe(
        ofType(AgentDataActionTypes.LoadAgentDataFromPouchFailure),
        map((action) => {
            const { error } = action.payload
            if (docNotFound(error)) {
                return new LoadAgentData({ data: { pstrFullRefresh: true } })
            } else {
                return new LoadAgentDataFailure({ error })
            }
        })
    )

    @Effect()
    loadAgentSettingsFromServer$ = this.actions$.pipe(
        ofType(AgentSettingsActionTypes.LoadAgentSettings),
        switchMap((action) =>
            this.apiService.getAgentSettings(action.payload.data).pipe(
                map((data) => {
                    return new LoadAgentSettingsSuccess({ data })
                }),
                catchError((error) =>
                    of(new LoadAgentSettingsFailure({ error }))
                )
            )
        )
    )

    @Effect()
    syncAgentSettings$ = this.actions$.pipe(
        ofType(AgentSettingsActionTypes.SyncAgentSettings),
        switchMap((action) =>
            this.apiService.syncAgentSettings(action.payload.data).pipe(
                map(
                    // TODO: Add error handling here
                    () =>
                        new LoadAgentSettings({
                            data: {
                                CID: action.payload.data.settingsForm.CID,
                                pstrAgentCode:
                                    action.payload.data.settingsForm.agentCode,
                            },
                        })
                )
            )
        )
    )

    @Effect()
    loadAgentSettingsFromPouch$ = this.actions$.pipe(
        ofType(AgentSettingsActionTypes.LoadAgentSettingsFromPouch),
        switchMap(() =>
            this.dbService.getAgentSettings().pipe(
                map((data) => new LoadAgentSettingsFromPouchSuccess({ data })),
                catchError((error) =>
                    of(new LoadAgentSettingsFailure({ error }))
                )
            )
        )
    )

    @Effect({ dispatch: false })
    saveAgentSettingsToPouch$ = this.actions$.pipe(
        ofType(AgentSettingsActionTypes.LoadAgentSettingsSuccess),
        map((action) =>
            this.dbService.saveAgentSettings(action.payload.data).pipe(
                map(() => EMPTY),
                catchError((error) => of(EMPTY))
            )
        )
    )

    @Effect({ dispatch: false })
    // ablmod230422 Piece of code of interest
    saveAgentDataToPouch$ = this.actions$.pipe(
        ofType(AgentDataActionTypes.LoadAgentDataSuccess),
        map((action) =>
            this.dbService.saveAgentData(action.payload.data).pipe(
                map(() => EMPTY),
                catchError((error) => of(EMPTY))
            )
        )
    )
}
