import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import {
    IAppState,
    IAgentSettings,
    ISaleNote,
    LoadStatus,
    SignatureTypes,
    IValidateSaleNote,
    IClient,
    ClientLookupType,
    ILoginResponse,
    IActiveSaleLot,
    ICustomizations,
} from 'src/types';

import {
    AgentDataActions,
    AgentDataActionTypes,
} from 'src/app/actions/agent-data.actions';
import {
    ClientActions,
    ClientActionTypes,
} from 'src/app/actions/client.actions';
import {
    AgentSettingsActionTypes,
    AgentSettingsActions,
} from '../actions/agent-setting.actions';
import { DeepReadonly } from 'utility-types';
import {
    SaleNoteActions,
    SaleNoteActionTypes,
} from '../actions/sale-note.actions';
import {
    SaleNotesDateRangeActions,
    SaleNotesDateRangeActionTypes,
} from '../actions/sale-notes-date-range.actions';
import { SyncSaleNoteActions } from '../actions/sync-sale-note.actions';
import {
    ToastMessageActions,
    ToastMessageActionTypes,
} from '../actions/toast-message.actions';
import { PdfActions, PdfActionTypes } from '../actions/pdf.actions';
import {
    SignatureActions,
    SignatureActionTypes,
} from '../actions/signature.actions';
import { clone, sortSaleNotes } from '../utilities';
import { NetworkActions, NetworkActionTypes } from '../actions/network.actions';
import { UserActions, UserActionTypes } from '../actions/user.actions';
import {
    ActiveSaleActions,
    ActiveSaleActionTypes,
} from '../actions/active-sale.actions';

export type ActionsUnion =
    | AgentDataActions
    | ClientActions
    | AgentSettingsActions
    | SaleNoteActions
    | SaleNotesDateRangeActions
    | SyncSaleNoteActions
    | ToastMessageActions
    | PdfActions
    | SignatureActions
    | NetworkActions
    | UserActions
    | ActiveSaleActions;

export const agentSettingsEmpty: DeepReadonly<IAgentSettings> = {
    settingsForm: {
        agentName: '',
        agentCode: '',
        agentEmail: '',
        agentMobilePhone: '',
        agentPassword: '',
        agentLocation: '',
        fullDetailPDF: '', // ablmod230426
        hidePriceAsGross: '', // ablmod230615
        nextSaleNoteNumber: '',
        CID: '',
        settingsPassword: '',
        settingsLocked: 'locked',
        settingsHash: '',
        strictOverrides: '', // ablmod230907
        syncDate: '',
    },
};

export const saleNoteEmpty: ISaleNote = {
    agentCode: '',
    agentEmail: '',
    agentLocation: '',
    agentMobilePhone: '',
    agentName: '',
    agentPassword: '',
    auditForm: {
        auditData: [],
    },
    lotsForm: {
        lotProducts: {},
        stockDescriptionTable: [],
    },
    nextSaleNoteNumber: '',
    notesForm: {
        administratorNotes: '',
        internalNotes: '',
        purchaserNotes: '',
        vendorNotes: '',
    },
    purchaserForm: {
        GSTNumber: '',
        NAITNumber: '',
        agentCode: '',
        agentName: '',
        dateOfBirth: '',
        emailAddress: '',
        entityName: '',
        entityType: {
            company: '0',
            individual: '0',
            other: '0',
            partnership: '0',
            trust: '0',
        },
        farmSourceLivestockAccount: '',
        fullName: '',
        herdPTPTCode: '',
        mobileNumber: '',
        phoneNumber: '',
        postalAddress: '',
        rebateRate: '',
    },
    saleNoteDetailsForm: {
        formVersion: '',
        saleNoteNumber: '',
        serverStatus: {
            message: '',
            status: '',
        },
    },
    saleNoteForm: {
        commissionPayable: {
            purchaser: '0',
            vendor: '1',
        },
        commissionRate: '',
        commissionType: {
            'per head': '0',
            percent: '0',
        },
        deliveryDate: '//', // ablmod230803 This was '' because it was defined as a string
        inCalfWarranty: {
            no: '0',
            yes: '0',
        },
        unitPrice: {
            'per head': '1',
            'price as gross': '0',
            'unit price': '0',
        },
        saleType: 'Private Sale',
        purchaserAgentSignature: '',
        purchaserPresenceOf: '',
        purchaserSignature: '',
        purchaserSignatureDate: '',
        vendorAgentSignature: '',
        vendorPresenceOf: '',
        vendorSignature: '',
        vendorSignatureDate: '',
    },
    settingsHash: '',
    settingsLocked: '',
    settingsPassword: '',
    syncDate: '',
    vendorForm: {
        GSTNumber: '',
        NAITNumber: '',
        agentCode: '',
        agentName: '',
        dateOfBirth: '',
        emailAddress: '',
        entityName: '',
        entityType: {
            company: '0',
            individual: '0',
            other: '0',
            partnership: '0',
            trust: '0',
        },
        farmSourceLivestockAccount: '',
        fullName: '',
        herdPTPTCode: '',
        mobileNumber: '',
        phoneNumber: '',
        postalAddress: '',
        rebateRate: '',
        security: '',
        thirdParty: {
            no: '0',
            yes: '0',
        },
    },
    workflowForm: {
        NAITTransfer: {
            '<none selected>': '0',
            complete: '0',
            'not applicable': '0',
        },
        animalRecordTransfer: {
            '<none selected>': '0',
            complete: '0',
            'not applicable': '0',
        },
        emailedToPurchaser: {
            '<none selected>': '0',
            complete: '0',
            'not applicable': '0',
        },
        emailedToVendor: {
            '<none selected>': '0',
            complete: '0',
            'not applicable': '0',
        },
        methodOfPayment: {
            '<none selected>': '0',
            EFT: '0',
            cheque: '0',
        },
        sentToServer: {
            '<none selected>': '0',
            'not sent': '0',
            complete: '0',
            ready: '0',
        },
    },
};

export function logger(reducer) {
    return function newReducer(state: IAppState, action) {
        console.group(action.type);
        const nextState = reducer(state, action);
        console.log(`%c prev state`, `color: #9E9E9E; font-weight: bold`, state);
        console.log(`%c action`, `color: #03A9F4; font-weight: bold`, action);
        console.log(
            `%c next state`,
            `color: #4CAF50; font-weight: bold`,
            nextState
        );
        // try {
        //     console.log(state.selectedSaleNote.saleNoteForm.commissionType)
        //     console.log(nextState.selectedSaleNote.saleNoteForm.commissionType)
        // } catch (error) {}

        console.groupEnd();
        return nextState;
    };
}

export function clients(currentClients = [], action) {
    switch (action.type) {
        case AgentDataActionTypes.LoadAgentDataSuccess:
            const loadedClients: IClient[] = action.payload.data.Client;
            console.log('ablmod230420 index function clients')
            loadedClients.sort((a, b) => {
                if (a?.SortCode < b?.SortCode) {
                    return -1;
                } else if (a?.SortCode > b?.SortCode) {
                    return 1;
                }
                return 0;
            });
            return loadedClients;
        case AgentDataActionTypes.ClearAgentDataFromPouch:
            return [];
        default:
            return currentClients;
    }
}

export function saleNotesDateRange(dateRange = '', action) {
    switch (action.type) {
        case SaleNotesDateRangeActionTypes.LoadSaleNotesDateRange:
            return action.payload.data.saleNotesDateRange;
        default:
            return dateRange;
    }
}

export function saleNotes(currentSaleNotes = [], action: SaleNoteActions) {
    switch (action.type) {
        case SaleNoteActionTypes.LoadSaleNotesSuccess:
            return sortSaleNotes(action.payload.data);
        case SaleNoteActionTypes.LoadSaleNotesFromPouchSuccess:
            return sortSaleNotes(action.payload.data);
        case SaleNoteActionTypes.DeleteSaleNote:
            return sortSaleNotes(
                deleteSaleNote(currentSaleNotes, action.payload.data)
            );
        case SaleNoteActionTypes.AppendSyncedSaleNote:
            return sortSaleNotes(
                appendSaleNote(currentSaleNotes, action.payload.data)
            );
        default:
            return sortSaleNotes(currentSaleNotes);
    }
}
function appendSaleNote(currentSaleNotes: ISaleNote[], saleNote: ISaleNote) {
    return [...currentSaleNotes, saleNote];
}

function deleteSaleNote(
    currentSaleNotes: ISaleNote[],
    saleNoteDelete: ISaleNote
): ISaleNote[] {
    const deleteIndex = currentSaleNotes.findIndex((value) => {
        return (
            value.saleNoteDetailsForm.saleNoteNumber ===
            saleNoteDelete.saleNoteDetailsForm.saleNoteNumber
        );
    });
    return currentSaleNotes
        .slice(0, deleteIndex)
        .concat(currentSaleNotes.slice(deleteIndex + 1));
}

function defineAddedFields(saleNote: ISaleNote) {
    const cloned = clone(saleNote);
    let touched = false;
    if (typeof saleNote.saleNoteForm.unitPrice !== 'object') {
        touched = true;
        cloned.saleNoteForm.unitPrice = clone(
            saleNoteEmpty.saleNoteForm.unitPrice
        );
    }

    if (typeof saleNote.saleNoteForm.saleType !== 'string') {
        touched = true;
        cloned.saleNoteForm.saleType = '';
    }

    if (touched) {
        return cloned;
    }
    return saleNote;
}

export function selectedSaleNote(
    saleNote: ISaleNote = clone(saleNoteEmpty),
    action: ActionsUnion
) {
    switch (action.type) {
        case SaleNoteActionTypes.SelectSaleNote:
            return defineAddedFields(action.payload.data);
        case SaleNoteActionTypes.NewSaleNote:
            return clone(saleNoteEmpty);
        case SignatureActionTypes.LoadSignature:
            return setSignature(
                saleNote,
                action.payload.data.type,
                action.payload.data.base64
            );
        case SaleNoteActionTypes.IncrementSaleNoteNumber: {
            const cloned: ISaleNote = JSON.parse(JSON.stringify(saleNote));
            cloned.saleNoteDetailsForm.saleNoteNumber = action.payload.data;
            return cloned;
        }

        case ClientActionTypes.LoadClientIntoSaleNote: {
            const { client, agentCode, type, agentName } = action.payload.data;
            return loadClientIntoSaleNote(
                saleNote,
                client,
                type,
                agentCode,
                agentName
            );
        }

        case SaleNoteActionTypes.AddLot: {
            const cloned = clone(saleNote);
            cloned.lotsForm.stockDescriptionTable.push(action.payload.data);
            return cloned;
        }

        case SaleNoteActionTypes.LoadSelectedSaleNoteSuccess: {
            return action.payload.data;
        }

        case SignatureActionTypes.ClearSignature:
            return setSignature(saleNote, action.payload.data.type, '');
        default:
            return saleNote;
    }
}

function loadClientIntoSaleNote(
    saleNote: ISaleNote,
    client: IClient,
    type: 'vendor' | 'purchaser',
    agentCode: string,
    agentName: string
): ISaleNote {
    const cloned = clone(saleNote);

    const saleNoteKey = type === 'vendor' ? 'vendorForm' : 'purchaserForm';

    const form = cloned[saleNoteKey];

    form.fullName = client.Name;
    form.phoneNumber = client.Phone;
    form.mobileNumber = client.Mobile;
    // ablmod231017 Adding in additional detail like suburb state postcode??? Weird it is not there.
    form.postalAddress = `${client.Address1}\n${client.Address2}\n${client.Address3}\n${client.District} ${client.Province} ${client.MailCode}`;
    form.emailAddress = client.Email;
    form.farmSourceLivestockAccount = client.Key;
    form.NAITNumber = client.NAIT || client.PIC;
    form.GSTNumber = client.TaxNumber;

    if (client.Name) {
        form.entityName = client.Name;
    }
    /* ablmod230505 Want to always load in the agent code / agent name
    if (client.AgentCode === agentCode) {
        form.agentCode = client.AgentCode;
        if (form.agentName === '') {
            form.agentName = agentName;
        }
        // form.agentName = client.Name
    }
    */
   form.agentCode = client.AgentCode // ablmod230505
   form.agentName = client.AgentName // ablmod230505

    if (form.fullName === '') {
        form.fullName = form.entityName;
    }

    return cloned;
}

function setSignature(
    saleNote: ISaleNote,
    signatureType: SignatureTypes,
    base64: string
) {
    const cloned: ISaleNote = JSON.parse(JSON.stringify(saleNote));
    if (signatureType === 'purchaser') {
        cloned.saleNoteForm.purchaserSignature = base64;
    } else if (signatureType === 'vendor') {
        cloned.saleNoteForm.vendorSignature = base64;
    } else if (signatureType === 'purchaser-witness') {
        cloned.saleNoteForm.purchaserAgentSignature = base64;
    } else if (signatureType === 'vendor-witness') {
        cloned.saleNoteForm.vendorAgentSignature = base64;
    } else {
        console.warn(signatureType + ' not recognized');
    }
    return cloned;
}

function getSignature(saleNote: ISaleNote, signatureType: SignatureTypes) {
    if (signatureType === 'purchaser') {
        return saleNote.saleNoteForm.purchaserSignature;
    } else if (signatureType === 'vendor') {
        return saleNote.saleNoteForm.vendorSignature;
    } else if (signatureType === 'purchaser-witness') {
        return saleNote.saleNoteForm.purchaserAgentSignature;
    } else if (signatureType === 'vendor-witness') {
        return saleNote.saleNoteForm.vendorAgentSignature;
    } else {
        console.warn(signatureType + ' not recognized');
    }
}

export function agentSettings(
    initialAgentSettings = agentSettingsEmpty,
    action: ActionsUnion
) {
    switch (action.type) {
        case AgentSettingsActionTypes.LoadAgentSettingsSuccess:
            return action.payload.data;
        case AgentSettingsActionTypes.LoadAgentSettingsFromPouchSuccess:
            return action.payload.data;
        case AgentSettingsActionTypes.ClearAgentSettings:
            return agentSettingsEmpty;
        default:
            return initialAgentSettings;
    }
}

// ablmod230427 Copying the above export withtout the multi action handling
export function agentSettings2(action) {
    return action.payload.data;
}

export function agentSettingsStatus(
    status: LoadStatus = 'unloaded',
    action: ActionsUnion
): LoadStatus {
    switch (action.type) {
        case AgentSettingsActionTypes.LoadAgentSettings:
            return 'loading';
        case AgentSettingsActionTypes.LoadAgentSettingsSuccess:
            return 'loaded';
        case AgentSettingsActionTypes.LoadAgentSettingsFailure:
            return 'failed';
        case AgentSettingsActionTypes.LoadAgentSettingsFromPouchSuccess:
            return 'loaded';
        default:
            return status;
    }
}

export function online(status = true, action: ActionsUnion): boolean {
    switch (action.type) {
        case NetworkActionTypes.Online:
            return true;
        case NetworkActionTypes.Offline:
            return false;
        default:
            return status;
    }
}

export function locations(locations = [], action) {
    switch (action.type) {
        case AgentDataActionTypes.LoadAgentDataSuccess:
            return Object.values(action.payload.data.Location);
        default:
            return locations;
    }
}

export function products(products = [], action) {
    switch (action.type) {
        case AgentDataActionTypes.LoadAgentDataSuccess:
            return Object.values(action.payload.data.Product);
        default:
            return products;
    }
}

export function toastMessage(options, action: ToastMessageActions) {
    switch (action.type) {
        case ToastMessageActionTypes.LoadToastMessage: {
            if (action.payload.data) {
                // action.payload.data.position = 'top'
            }
            return action.payload.data;
        }
        default:
            return options;
    }
}

export function pdfs(
    initialPdfs = {
        vendor: undefined,
        purchaser: undefined,
        administrator: undefined,
    },
    action: PdfActions
) {
    switch (action.type) {
        case PdfActionTypes.LoadPdfSuccess:
            console.log(action);
            return action.payload.data;

        default:
            return initialPdfs;
    }
}

export const initialValidSaleNote: IValidateSaleNote = {
    'saleNoteDetailsForm.saleNoteNumber': true,
    'vendorForm.entityName': true,
    'vendorForm.agentCode': true,
    'vendorForm.agentName': true,
    'vendorForm.NAITNumber': true,
    'purchaserForm.entityName': true,
    'purchaserForm.agentCode': true,
    'purchaserForm.agentName': true,
    'purchaserForm.NAITNumber': true,
    'saleNoteForm.deliveryDate': true,
    'saleNoteForm.commissionRate': true,
    'saleNoteForm.commissionType': true,
    'saleNoteForm.commissionPayable': true,
    'lotsForm.lots': true,
};

export function validSaleNote(
    valid: IValidateSaleNote = initialValidSaleNote,
    action: SaleNoteActions
) {
    switch (action.type) {
        case SaleNoteActionTypes.ValidateSaleNote:
            return validateSaleNote(action.payload.data, action.payload.validationOptions);
        case SaleNoteActionTypes.ResetValidation:
            return clone(initialValidSaleNote);
        default:
            return valid;
    }
}

function validateSaleNote(saleNote: ISaleNote, validation?: ICustomizations): IValidateSaleNote {
    const valid: IValidateSaleNote = JSON.parse(
        JSON.stringify(initialValidSaleNote)
    );
    let isHidden: boolean;

    if (saleNote.saleNoteDetailsForm.saleNoteNumber === '') {
        valid['saleNoteDetailsForm.saleNoteNumber'] = false;
    }

    if (saleNote.saleNoteForm.deliveryDate === '' || saleNote.saleNoteForm.deliveryDate === '//' ) { // ablmod230803 Trapping a reset date
        valid['saleNoteForm.deliveryDate'] = false;
    }
    // ablmod230921 Maybe add some validation here.
    if (saleNote.saleNoteForm.commissionRate === '') {
        valid['saleNoteForm.commissionRate'] = false;
    }

    const validCommissionType = Object.values(
        saleNote.saleNoteForm.commissionType
    ).some((value) => value === '1');

    if (!validCommissionType) {
        valid['saleNoteForm.commissionType'] = false;
    }

    const validCommissionPayable = Object.values(
        saleNote.saleNoteForm.commissionPayable
    ).some((value) => value === '1');

    if (!validCommissionPayable) {
        valid['saleNoteForm.commissionPayable'] = false;
    }

    if (saleNote.lotsForm.stockDescriptionTable.length === 0) {
        valid['lotsForm.lots'] = false;
    }

    if (saleNote.vendorForm.entityName === '') {
        valid['vendorForm.entityName'] = false;
    }

    if (saleNote.vendorForm.agentName === '') {
        valid['vendorForm.agentName'] = false;
    }

    if (saleNote.vendorForm.agentCode === '') {
        valid['vendorForm.agentCode'] = false;
    }
    // ablmod230514a Refresh
    /* ablmod230514a This logic is not required - because it will entered into the customisation if it is needed to be mandatory.
    isHidden = validation['vendorForm.NAITNumber'].hidden === '1'; // ablmod230514a
    // ablmod230514a Tracking down why the NAIT/PIC number is flagging as required - when the customistations say it is not
    if (!isHidden && saleNote.vendorForm.NAITNumber === '') { // ablmod230514a
        valid['vendorForm.NAITNumber'] = false; // ablmod230514a
    } // ablmod230514a
    */ // ablmod230514a

    if (saleNote.purchaserForm.entityName === '') {
        valid['purchaserForm.entityName'] = false;
    }

    if (saleNote.purchaserForm.agentName === '') {
        valid['purchaserForm.NAITNumber'] = false;
    }

    if (saleNote.purchaserForm.agentCode === '') {
        valid['purchaserForm.agentCode'] = false;
    }

    /* ablmod230514a This logic is not required as it will be entered into the customisation if it is mandatory
    isHidden = validation['purchaserForm.NAITNumber'].hidden === '1'; // ablmod230514a
    if (!isHidden && saleNote.purchaserForm.NAITNumber === '') { // ablmod230514a
        valid['purchaserForm.NAITNumber'] = false; // ablmod230514a
    } // ablmod230514a
    */ // ablmod230514a

    if (validation) {
        for (const [key, customization] of Object.entries(validation)) {
            const [formKey, fieldKey] = key.split('.');
            // ablmod230514a console.log('ablmod230514 '+formKey+' '+fieldKey+' '+customization.required)            
            if (customization.required && customization.required === '1') {
                if (saleNote[formKey][fieldKey] === '') {
                    valid[`${formKey}.${fieldKey}`] = false // ablmod230514a Found this line wwas remmed out.
                }
            } 
        }
    }
    return valid;
}

export function viewClients(
    initialState: { type: ClientLookupType; search: string; } = {
        type: '',
        search: '',
    },
    action: ClientActions
) {
    switch (action.type) {
        case ClientActionTypes.SetClientLookupType:
            return {
                ...initialState,
                type: action.payload.data.type,
                search: action.payload.data.search || '',
            };
        default:
            return initialState;
    }
}

export function loggedIn(initialState = false, action: UserActions) {
    switch (action.type) {
        case UserActionTypes.LoginSuccess:
            return true;
        case UserActionTypes.LoginFailure:
            return false;
        case UserActionTypes.Logout:
            return false;

        default:
            return initialState;
    }
}

export function clockExpiry(initialState = undefined, action: UserActions) {
    switch (action.type) {
        case UserActionTypes.LoginSuccess:
            return  action.payload.data.clockExpiry;
        default:
            return initialState;
    }
}

const initSaleType = [
    {
        Key: '',
        Name: 'Private Sale',
    },
];

export function saleTypes(state = initSaleType, action: AgentDataActions) {
    switch (action.type) {
        case AgentDataActionTypes.LoadAgentDataSuccess: {
            const type = action.payload.data.SaleNoteType;
            if (type) {
                return Object.values(action.payload.data.SaleNoteType);
            } else {
                return state;
            }
        }
        case AgentDataActionTypes.LoadAgentDataFromPouchSuccess: {
            const type = action.payload.data.SaleNoteType;
            if (type) {
                return Object.values(action.payload.data.SaleNoteType);
            } else {
                return state;
            }
        }
        default:
            return state;
    }
}

export function activeSales(state = { Sales: {} }, action: ActiveSaleActions) {
    switch (action.type) {
        case ActiveSaleActionTypes.LoadActiveSalesSuccess:
            return clone(action.payload.data);
        default:
            return state;
    }
}

function mergeLots(oldLots: IActiveSaleLot[], newLots: IActiveSaleLot[]) {

    const hasItem = item => oldLots.some(lot => {
        return lot.Item === item;
    });

    if (newLots.length === 1) {
        if (hasItem(newLots[0].Item)) {
            console.error('edit');
            const newLot = newLots[0];
            return oldLots.map(lot => {
                if (lot.Item === newLot.Item) {
                    return newLot;
                } else {
                    return lot;
                }
            });
        } else {
            console.error('add');
            return oldLots.concat(newLots);
        }
    } else {
        console.error('delete');
        return newLots;
    }


}

export function lots(initialLots = {}, action: ActiveSaleActions) {
    switch (action.type) {
        case ActiveSaleActionTypes.LoadLotsSuccess: {
            const { Lots } = action.payload.data.response;

            let updatedLots: IActiveSaleLot[] = initialLots[action.payload.data.saleID];

            if (updatedLots) {
                updatedLots = mergeLots(updatedLots, Lots);
            } else {
                console.error('No updated lots');
                updatedLots = Lots;
            }

            return clone({
                ...initialLots,
                [action.payload.data.saleID]: updatedLots,
            });
        }

        default:
            return initialLots;
    }
}

export function loginResponse(initialState = null, action): ILoginResponse {
    switch (action.type) {
        case UserActionTypes.LoginSuccess:
            const response = action.payload.data.response;
            if (response) {
                if (response.Menu) {
                    if (Object.values(response.Menu).length > 0) {
                        return response;
                    }
                }
            }

            break;

        default:
            break;
    }
    return initialState;
}

export function loginStatus(status = '', action: UserActions) {
    switch (action.type) {
        case UserActionTypes.Login:
            return 'loading';
        case UserActionTypes.LoginFailure:
            return 'failed';
        default:
            return '';
    }
}

const blankGif = '';

export function pdfLogo(init = blankGif, action: PdfActions) {
    switch (action.type) {
        case PdfActionTypes.SetLogo:
            return action.payload.logo;
    }
    return init;
}

export function relatedData(init, action: ActiveSaleActions) {
    switch (action.type) {
        case ActiveSaleActionTypes.GetRelatedDataSuccess:
            return action.payload.data;
    }
    return init;
}

export function selectedActiveSale(init = null, action: ActiveSaleActions) {
    return init;
}

export function saleNotesLoaded(init = false, action: SaleNoteActions) {
    switch (action.type) {
        case SaleNoteActionTypes.LoadSaleNotesSuccess:
            return true;
        // case SaleNoteActionTypes.LoadSaleNotesFromPouchSuccess:
        //     return true
    }
    return init;
}

export function activeSalesLoaded(state = false, action: ActiveSaleActions) {
    switch (action.type) {
        case ActiveSaleActionTypes.LoadActiveSalesSuccess:
            return true;

        default:
            return state;
    }
}

export function saleNotesFetched(state = null, action: SaleNoteActions) {
    switch (action.type) {
        case SaleNoteActionTypes.SaleNotesFetched:
            return action.payload.data;

        default:
            return state;
    }
}

export const reducers: ActionReducerMap<IAppState, ActionsUnion> = {
    pdfLogo,
    saleNotesFetched,
    loginStatus,
    loginResponse,
    lots,
    activeSales,
    loggedIn,
    clients,
    saleNotesDateRange,
    saleNotes,
    saleNotesLoaded,
    selectedSaleNote,
    agentSettings,
    agentSettingsStatus,
    online,
    locations,
    products,
    toastMessage,
    pdfs,
    validSaleNote,
    viewClients,
    saleTypes,
    relatedData,
    selectedActiveSale,
    activeSalesLoaded,
    clockExpiry,
};

export const metaReducers: MetaReducer<IAppState>[] = !environment.production
    ? [logger]
    : [];
