import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { IAppState, ICustomizations, ILoginResponse, ISaleNote } from 'src/types'

export function docNotFound(error) {
    const docNotFoundError = {
        error: true,
        name: 'not_found',
    }
    return Object.keys(docNotFoundError).every(
        (key) => docNotFoundError[key] === error[key]
    )
}

export function clone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object))
}

export function toDataURL(url, callback: (string: string) => void) {
    const xhr = new XMLHttpRequest()
    xhr.onerror = () => { }
    xhr.onload = function () {
        const reader = new FileReader()
        reader.onloadend = function () {
            if (typeof reader.result === 'string') {
                callback(reader.result)
            } else {
                const enc = new TextDecoder()
                const arr = new Uint8Array(reader.result)
                const decoded = enc.decode(arr)
                callback(decoded)
                // callback(reader.result)
            }
        }
        reader.readAsDataURL(xhr.response)
    }

    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
}
export function updateSelect(
    e: CustomEvent,
    saleNote: ISaleNote,
    [formKey, controlKey]: [string, string]
): ISaleNote {
    const cloned = clone(saleNote)
    const { value } = e.detail

    if (value in cloned[formKey][controlKey]) {
        for (const key in cloned[formKey][controlKey]) {
            if (key === value) {
                cloned[formKey][controlKey][key] = '1'
            } else {
                cloned[formKey][controlKey][key] = '0'
            }
        }
    } else if (typeof value === 'string') {
        cloned[formKey][controlKey][value] = '1'
    } else if (typeof value === 'undefined') {
        for (const key in cloned[formKey][controlKey]) {
            if (key in cloned[formKey][controlKey]) {
                cloned[formKey][controlKey][key] = '0'
            }
        }
    }

    return cloned
}

export const okCloseButton = [
    {
        text: 'OK',
        role: 'cancel',
        handler: () => { },
    },
]

export function isUpToDate(loadedVersion: string): Promise<boolean> {
    const headers = new Headers()
    headers.append('pragma', 'no-cache')
    headers.append('cache-control', 'no-cache')

    const options = {
        method: 'GET',
        headers: headers,
    }
    return fetch('/assets/version.txt?r=' + Math.random(), options)
        .then((r) => r.text())
        .then((t) => t === loadedVersion)
        .catch(error => true)
}

export function afterServiceWorkerUpdated(cb: () => void) {
    if (
        'serviceWorker' in navigator &&
        ['localhost', '127'].indexOf(location.hostname) === -1
    ) {
        // register service worker file
        navigator.serviceWorker
            .register('ngsw-worker.js?r=' + Math.random().toString())
            .then((reg) => {
                reg.onupdatefound = () => {
                    const installingWorker = reg.installing
                    installingWorker.onstatechange = () => {
                        switch (installingWorker.state) {
                            case 'installed':
                                if (navigator.serviceWorker.controller) {
                                    // new update available
                                    console.log('updates available')
                                    cb()
                                } else {
                                    // no update available
                                    console.log('no updates available')
                                }
                                break
                        }
                    }
                }
            })
            .catch((err) => console.error('[SW ERROR]', err))
    }
}

class IStoreComponent extends Component {
    store: Store<IAppState>
    loginResponse: ILoginResponse
    customizations: ICustomizations
}

export function applyCustomizationsToComponent(component: IStoreComponent) {
    component.store.select('loginResponse').subscribe((loginResponse) => {
        component.loginResponse = loginResponse
        if (!loginResponse) {
            return
        }
        if (loginResponse.customizations) {
            const customizations = loginResponse.customizations['Enter Sale Note']
            for (const key in customizations) {
                if (!(key in customizations)) {
                    continue
                }
                const customs = customizations[key]
                for (const custom in customs) {
                    if (!(custom in customs)) { continue }
                    if (typeof component.customizations[key] === 'undefined') {
                        component.customizations[key] = {
                            hidden: customs.hidden || '0',
                            label: customs.label,
                            required: customs.required || '0',
                            regex: '',
                            tooltip: '',
                            validation: '',
                        }
                    }

                    component.customizations[key][custom] = customs[custom]
                }
            }
        }

        if (loginResponse.customLabels) {
            for (const key in loginResponse.customLabels) {
                if (typeof loginResponse.customLabels[key] === 'string') {
                    // component.labels[key] = loginResponse.customLabels[key]
                }
            }
        }
    })
}

export const checkForUpdate = () =>
    new Promise(function (resolve, reject) {
        // lazy way of disabling service workers while developing
        if (
            'serviceWorker' in navigator &&
            ['localhost', '127'].indexOf(location.hostname) === -1
        ) {
            // register service worker file
            navigator.serviceWorker
                .register('ngsw-worker.js') // ?r=' + Math.random().toString())
                .then((reg) => {
                    reg.onupdatefound = () => {
                        const installingWorker = reg.installing
                        installingWorker.onstatechange = () => {
                            switch (installingWorker.state) {
                                case 'installed':
                                    if (navigator.serviceWorker.controller) {
                                        // new update available
                                        console.log('update available')
                                        resolve(true)
                                    } else {
                                        // no update available
                                        console.log('no update available')
                                        resolve(false)
                                    }
                                    break
                            }
                        }
                    }
                })
                .catch((err) => console.error('[SW ERROR]', err))
        }
    })

const { polyfill } = require('../text-encode')

export function hash(s: string): Promise<string> {
    let encoder
    if (typeof TextEncoder === 'undefined') {
        encoder = polyfill()
    } else {
        encoder = new TextEncoder()
    }

    const data = encoder.encode(s)
    const passwordHash = Promise.resolve(
        window.crypto.subtle.digest('SHA-256', data)
    )
    return passwordHash.then((a) => hexString(a))
}

export function hexString(buffer: ArrayBuffer): string {
    const byteArray = new Uint8Array(buffer)

    const hexCodes = [...byteArray].map((value) => {
        const hexCode = value.toString(16)
        const paddedHexCode = hexCode.padStart(2, '0')
        return paddedHexCode
    })

    return hexCodes.join('')
}

const SALT = "SHvfN989wo4hboEjBhsRF32p7KLhFFJ8"
export function obfuscatedPw(value: string, encrypt: boolean): string {
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(SALT).reduce((a, b) => a ^ b, code);
    if (encrypt) {
        return value
            .split("")
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join("");
    } else {
        return value
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
    }
}

export interface IReferenceObject {
    [key: string]: [string, string]
}

export function setSelects(component: any) {
    const { selectedSaleNote } = component
    const referenceObject: IReferenceObject = component.referenceObject
    for (const [reference, [formKey, controlKey]] of Object.entries(
        referenceObject
    )) {
        if (component[reference]) {
            if (!selectedSaleNote[formKey][controlKey]) {
                console.error(formKey, controlKey, selectedSaleNote)
            }
            let set = false
            for (const [key, value] of Object.entries(
                selectedSaleNote[formKey][controlKey]
            )) {
                if (value === '1') {
                    set = true
                    component[reference].value = key
                }
            }
            if (!set) {
                component[reference].value = ''
            }
        }
    }
}

export function sortSaleNotes(array: ISaleNote[]) {
    const getNumber = (saleNote: ISaleNote) => {
        return parseInt(
            saleNote.saleNoteDetailsForm.saleNoteNumber.split('-')[2],
            10
        )
    }
    array = clone(array)

    return array
        .sort((a, b) => {
            return getNumber(a) - getNumber(b)
        })
        .reverse()
}

export function hasAdminPage(appState: IAppState) {
    return appState.loginResponse.Menu
        .map(({ ScreenName }) => ScreenName)
        .includes('Administration')
}
