import {
    Injectable
} from '@angular/core'
import {
    HttpClient,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http'
import {
    IAgentSettings,
    ISyncAgentSettingsResponse,
    IGetAgentDataResponse,
    ISaleNote,
    ISyncSaleNoteResponse,
    ILoginResponse,
    IGetActiveSalesResponse,
    IGetLotsResponse,
    IActiveSaleLot,
    ISaveLotsRequest,
    IClient,
    ISaleG8FetchListResponse,
    ISaleG8FetchListParameters,
    ISaleG8FetchReportParameters,
    ISaleG8FetchReportResponse,
    ILot,
    IAppState
} from 'src/types'

import {
    environment
} from 'src/environments/environment'
import {
    Observable,
    Subscription,
    of
} from 'rxjs'
import {
    catchError,
    map,
    switchMap,
    tap
} from 'rxjs/operators'
import {
    clone
} from '../utilities'

import { version as agriG8Version } from '../../../package.json'
import { Store } from '@ngrx/store'
// import { TokenService } from './token.service'

const jsonHeaders = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    API_HOST = environment.production ?
        'https://relayprod.saleg8.net' :
        'https://relaytest.saleg8.net'
    allowedMenus = []
    agentSettings: IAgentSettings | undefined = undefined;
    agentSettingsSubscription: Subscription;
    constructor(private http: HttpClient, private store: Store<IAppState>) {
        this.agentSettingsSubscription = this.store.select('agentSettings').subscribe((settings) => {
            this.agentSettings = settings;
        })
    }

    saleG8FetchGraphDef(parameters) {
        const { CID } = parameters
        return this.http.post<any>(
            `${this.API_HOST}/api/SaleG8FetchGraphDef?CID=${CID}`,
            { ...parameters, agriG8Version },
            jsonHeaders
        )
    }

    saleG8FetchGraphData(parameters) {
        const { CID } = parameters
        return this.http.post<any>(
            `${this.API_HOST}/api/SaleG8FetchGraphData2?CID=${CID}`,
            { ...parameters, agriG8Version },
            jsonHeaders
        )
    }

    saleG8FetchReport(parameters: ISaleG8FetchReportParameters) {
        const { CID } = parameters
        return this.http.post<ISaleG8FetchReportResponse>(
            `${this.API_HOST}/api/SaleG8FetchReport?CID=${CID}`,
            { ...parameters, agriG8Version },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }

        )
    }

    saleG8FetchList(parameters: ISaleG8FetchListParameters) {
        const { CID } = parameters
        return this.http.post<ISaleG8FetchListResponse>(
            `${this.API_HOST}/api/SaleG8FetchList?CID=${CID}`,
            { ...parameters, agriG8Version },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            }
        )
    }

    getRelatedData({
        pstrSaleID,
        CID
    }: {
        pstrSaleID: string; CID: string
    }) {
        return this.http.post(
            `${this.API_HOST}/api/GetRelatedData?CID=${CID}`, {
            pstrSaleID, agriG8Version
        }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        }
        )
    }

    setHost(host: string) {
        this.API_HOST = host
    }

    saveLots({
        CID,
        pstrSaleID,
        Lots, pstrAgentCode
    }) {
        return this.http.post<{
            result: 'OK' | 'NG'; Lots: IActiveSaleLot[]
        }>(
            `${this.API_HOST}/api/SaveLots?CID=${CID}`, {
            pstrSaleID,
            pstrAgentCode,
            Lots, agriG8Version
        }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        }
        )
    }

    getLots({
        pstrSaleID,
        CID
    }: {
        pstrSaleID: string; CID: string
    }) {
        return this.http.post<IGetLotsResponse>(
            `${this.API_HOST}/api/GetLots?CID=${CID}`, {
            pstrSaleID, agriG8Version
        }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        }
        )
    }

    getActiveSales({
        CID
    }: {
        CID: string
    }) {
        return this.http.post<IGetActiveSalesResponse>(
            `${this.API_HOST}/api/GetActiveSales?CID=${CID}`, { agriG8Version }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        )
    }

    async dynamicAPI({
        CID,
        method,
        json
    }: {
        CID: string,
        method: string,
        json: any
    }): Promise<any | {
        error: any
    }> {
        json.agriG8Version = agriG8Version
        const post = this.http.post(
            `${this.API_HOST}/api/${method}?CID=${CID}`,
            json, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        )

        try {
            const response = await post.toPromise()
            return response
        } catch (error) {
            return {
                error
            }
        }


    }

    searchClient({
        CID,
        pstrSearchTerm
    }) {
        return this.http.post(
            `${this.API_HOST}/api/SearchClient?CID=${CID}`, {
            pstrSearchTerm, agriG8Version
        }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        )
    }

    deleteLot({
        CID,
        pstrSaleID,
        pstrItem
    }) {
        return this.http.post(
            `${this.API_HOST}/api/DeleteLot?CID=${CID}`,
            { pstrSaleID, pstrItem, agriG8Version },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        )
    }


    nextItem({
        pstrSaleID,
        CID,
        pstrAgentCode
    }: {
        pstrSaleID: string,
        CID: string,
        pstrAgentCode: string
    }): Observable<{
        result: string,
        nextItem: string,
        nextLot?: ILot
    }> {
        return this.http.post<{
            result: string,
            nextItem: string,
        }>(
            `${this.API_HOST}/api/NextItem?CID=${CID}`,
            {
                pstrSaleID,
                pstrAgentCode, agriG8Version, sendLot: '1'
            },
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }
        )
    }

    login({
        CID,
        pstrUserID,
        pstrPassword,
    }: {
        CID: string
        pstrUserID: string
        pstrPassword: string
    }) {
        const url = `${this.API_HOST}/api/Signon?CID=${CID}`
        return this.http
            .post<ILoginResponse>(
                url, {
                pstrUserID,
                pstrPassword, agriG8Version
            }, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
                observe: 'response',

            }
            )
            .pipe(
                tap((response) => {
                    // response.
                    const loginResponse = response.body
                    if (loginResponse.Menu) {
                        this.allowedMenus = Object.values(
                            loginResponse.Menu
                        ).map(({
                            ScreenName
                        }) => ScreenName)
                    }
                })
            )
    }

    syncSaleNoteLog({
        pstrTimeStamp,
    }: {
        pstrTimeStamp: string
    }) {
        const { CID, agentCode: pstrAgentCode } = this.agentSettings.settingsForm;
        return this.http.post(
            `${this.API_HOST}/api/SyncSaleNoteLog?CID=${CID}`, {
            pstrAgentCode,
            pstrTimeStamp, agriG8Version
        }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        }
        )
    }

    loggedIn(): Observable<boolean> {
        return of(Math.random() > 0.5 ? false : true)
    }

    upload({
        pstrSaleNote,
        pstrAgentCode,
        CID,
        pstrBase64,
        pstrFileName,
    }: {
        pstrSaleNote: string
        pstrAgentCode: string
        CID: string
        pstrBase64: string
        pstrFileName: string
    }) {
        const url = `${this.API_HOST}/api/Upload?CID=${CID}`
        const body = {
            pstrSaleNote,
            pstrAgentCode,
            pstrBase64,
            pstrFileName, agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post(url, body, {
            headers
        })
    }

    email({
        pstrSaleNote,
        pstrAgentCode,
        pstrSendMode,
        CID,
    }: {
        pstrSaleNote: string
        pstrAgentCode: string
        pstrSendMode: 'V' | 'P' | ''
        CID: string
    }) {
        const url = `${this.API_HOST}/api/Email?CID=${CID}`
        const body = {
            pstrSaleNote,
            pstrAgentCode,
            pstrSendMode,
            agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post(url, body, {
            headers
        })
    }

    syncSaleNote({
        pstrAgentCode,
        saleNote,
        agentSettings,
    }: {
        pstrAgentCode: string
        saleNote: ISaleNote
        agentSettings: IAgentSettings
    }) {
        const {
            CID
        } = agentSettings.settingsForm
        const body = {
            pstrAgentCode,
            pstrSaleNote: saleNote.saleNoteDetailsForm.saleNoteNumber,
            data: Object.assign({}, saleNote, agentSettings.settingsForm),
            agriG8Version
        }
        const url = `${this.API_HOST}/api/SyncSaleNote?CID=${CID}`
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post<ISyncSaleNoteResponse>(url, body, {
            headers
        })
    }

    getSaleNotes({
        CID,
        pstrAgentCode,
        pstrDateRange,
    }: {
        CID: string
        pstrAgentCode: string
        pstrDateRange: string
    }) {
        const url = `${this.API_HOST}/api/GetSaleNotes?CID=${CID}`
        const body = {
            pstrAgentCode,
            pstrMonth: pstrDateRange || '3', agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post<ISaleNote[]>(url, body, {
            headers,
        })
    }

    deleteSaleNote({
        pstrSaleNote,
        pstrAgentCode,
        CID,
    }: {
        pstrSaleNote: string
        pstrAgentCode: string
        CID: string
    }) {
        const url = `${this.API_HOST}/api/DeleteSaleNote?CID=${CID}`
        const body = {
            pstrSaleNote,
            pstrAgentCode, agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post(url, body, {
            headers
        })
    }

    getAgentData({
        CID,
        pstrAgentCode,
        pstrFullRefresh
    }): Observable<IGetAgentDataResponse> {
        const url = `${this.API_HOST}/api/GetAgentData?CID=${CID}`
        const body = {
            pstrAgentCode,
            pstrFullRefresh,
            pstrRemoveClients: '0', agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        
        return this.getClientsJsonStream({
            CID,
            pstrAgentCode,
            pstrFullRefresh
        }).pipe(
            switchMap(clients => {
                body.pstrRemoveClients = '1'
                return this.http.post<IGetAgentDataResponse>(url, body, {
                    headers,
                }).pipe(
                    switchMap(response => {
                        response.Client = clients
                        return of(response)
                    })
                )
            }),
            catchError((error, caught) => {
                console.error(error)
                return this.http.post<IGetAgentDataResponse>(url, body, {
                    headers,
                })
            })
        )
    }

    // Check if GetClientsJsonStream exists
    // If it returns clients pass a parameter to GetAgentData indicating it does
    // not need to send clients
    // If it does not call GetAgentData as per usual


    getClientsJsonStream({
        CID,
        pstrAgentCode,
        pstrFullRefresh
    }) {
        const url = `${this.API_HOST}/api/GetClientsJsonStream?CID=${CID}`
        const body = {
            pstrAgentCode,
            pstrFullRefresh, agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post<IClient[]>(url, body, {
            headers,
        })
    }

    getAgentSettings({
        CID,
        pstrAgentCode,
    }: {
        CID: string
        pstrAgentCode: string
    }) {
        const url = `${this.API_HOST}/api/GetAgentSettings?CID=${CID}`
        const body = {
            pstrAgentCode, agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        const observable = this.http.post<IAgentSettings>(url, body, {
            headers,
        })
        return observable
    }

    syncAgentSettings(agentSettings: IAgentSettings) {
        agentSettings = clone(agentSettings)
        const {
            CID,
            agentCode: pstrAgentCode
        } = agentSettings.settingsForm
        const url = `${this.API_HOST}/api/SyncAgentSettings?CID=${CID}`
        agentSettings.settingsForm[CID] = undefined
        const body = {
            pstrAgentCode,
            ...agentSettings,
            agriG8Version
        }
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        })
        return this.http.post<ISyncAgentSettingsResponse>(url, body, {
            headers,
        })
    }
}
