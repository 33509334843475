import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, from, interval } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState, ICountDown } from 'src/types';

@Injectable({
	providedIn: 'root'
})
export class ClockCheckerService {

	private _clockError = new BehaviorSubject<boolean>(false);
	private clockError$: Observable<boolean> = this._clockError.asObservable();

	private countdownInterval = 1000; // Update every second

	constructor(
		private store: Store<IAppState>
	) { }

	setClockError() {
		this._clockError.next(true);
	}

	resetClockError() {
		this._clockError.next(false);
	}

	clockError() {
		return this.clockError$;
	}

	getClockCounter(): Observable<ICountDown> {
		return this.store.select('clockExpiry').pipe(
			switchMap((expiry: number | undefined) => {
				if (!expiry) {
					return of({ expired: false, countdown: undefined})
				}

				return interval(this.countdownInterval).pipe(
					map(() => {
						const remainingTime = expiry - Date.now();
						const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
						const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
						const seconds = Math.floor((remainingTime / 1000) % 60);
						if (hours + minutes + seconds > 0) {
							return { expired: false, countdown: this.padParts({h: hours, m: minutes, s: seconds}) }
						}
						return { expired: true, countdown: { hours: 0, minutes: 0, seconds: 0 } }
					})
				)
			})
		)
	}

	private padParts(parts: { h: number, m: number, s: number }) {
		return {
			hours: String(parts.h).padStart(2, "0"),
			minutes: String(parts.m).padStart(2, "0"),
			seconds: String(parts.s).padStart(2, "0")
		}
	}

}
