import { Action } from '@ngrx/store'
import { ILoginResponse } from 'src/types'

export enum UserActionTypes {
    Login = '[User] Login',
    LoginSuccess = '[User] Login Success',
    LoginFailure = '[User] Login Failure',
    Logout = '[User] Logout'
}

export class Login implements Action {
    readonly type = UserActionTypes.Login
    constructor(
        public payload: {
            data: {
                redirect: boolean
                CID: string
                agentCode: string
                password: string
            }
        }
    ) {}
}

export class LoginSuccess implements Action {
    readonly type = UserActionTypes.LoginSuccess
    constructor(
        public payload: {
            fetchSaleNotes: boolean,
            data: {
                redirect: boolean
                CID: string
                agentCode: string
                loggedIn: boolean
                clockExpiry: number
                response: ILoginResponse
            }
        }
    ) {}
}

export class LoginFailure implements Action {
    readonly type = UserActionTypes.LoginFailure
    constructor(public payload: { error: any }) {}
}

export class Logout implements Action {
    readonly type = UserActionTypes.Logout
}

export type UserActions = Login | LoginSuccess | LoginFailure | Logout
