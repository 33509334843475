import { Action } from '@ngrx/store'
import { ISaleNote, ISaleNotePdfs } from 'src/types'

export enum PdfActionTypes {
    LoadPdf = '[Pdf] Load Pdf',
    LoadPdfSuccess = '[Pdf] Load Pdf Success',
    LoadPdfFailure = '[Pdf] Load Pdf Failure',
    EmailPdf = '[Pdf] Email Pdf',
    SetLogo = '[Pdf] Set Logo',
    LoadLogo = '[Pdf] Load Logo',
    LoadSavedLogo = '[Pdf] Load Saved Logo',
}

export class LoadPdf implements Action {
    readonly type = PdfActionTypes.LoadPdf
    constructor(public payload: { data: ISaleNote }) {}
}

export class LoadPdfSuccess implements Action {
    readonly type = PdfActionTypes.LoadPdfSuccess
    constructor(public payload: { data: ISaleNotePdfs }) {}
}

export class LoadPdfFailure implements Action {
    readonly type = PdfActionTypes.LoadPdfFailure
    constructor(public payload: { error: any }) {}
}

export class SetLogo implements Action {
    readonly type = PdfActionTypes.SetLogo
    constructor(public payload: { logo: string }) {}
}

export class LoadLogo implements Action {
    readonly type = PdfActionTypes.LoadLogo
    constructor(public payload: { logo: string }) {}
}

export class LoadSavedLogo implements Action {
    readonly type = PdfActionTypes.LoadSavedLogo
}

type Base64String = string

export class EmailPdf implements Action {
    readonly type = PdfActionTypes.EmailPdf
    constructor(
        public payload: {
            data: {
                manualEmail?: boolean
                pdf: Base64String
                mode: 'V' | 'P' | ''
                saleNoteNumber: string // ablmod231006 Keeping the saleNoteNumber in the same object to avoid async confusion
            }
        }
    ) {}
}

export type PdfActions =
    | LoadPdf
    | LoadPdfSuccess
    | LoadPdfFailure
    | EmailPdf
    | SetLogo
    | LoadLogo
    | LoadSavedLogo
