import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'

import {
    IonicModule,
    IonicRouteStrategy,
    ToastController,
} from '@ionic/angular'
import { SplashScreen } from '@ionic-native/splash-screen/ngx'
import { StatusBar } from '@ionic-native/status-bar/ngx'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { StoreModule } from '@ngrx/store'
import { reducers, metaReducers } from './reducers'
import { EffectsModule } from '@ngrx/effects'
import { AppEffects } from './app.effects'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ReactiveFormsModule } from '@angular/forms'
import { File } from '@ionic-native/file/ngx'
import { FileOpener } from '@ionic-native/file-opener/ngx'
import { FileTransfer } from '@ionic-native/file-transfer/ngx'
import { DocumentViewer } from '@ionic-native/document-viewer/ngx'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { Network } from '@ionic-native/network/ngx'
import { AngularWebStorageModule, LocalStorageService } from 'angular-web-storage'
import { IonicSelectableModule } from 'ionic-selectable'
// import { Agrig8InputModule } from './components/agrig8-input/agrig8-input.module'
// import { MatDatepickerModule } from '@angular/material/datepicker'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MAT_DATE_LOCALE } from '@angular/material/core'
// import { httpInterceptorProviders } from './services/http-interceptors'
// import { TokenService } from './services/token.service'
import { InterceptorService } from './services/interceptor.service'


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        IonicSelectableModule,
        AngularWebStorageModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: false,
                strictStateSerializability: true,
                strictActionSerializability: false,
            },
        }),
        EffectsModule.forRoot([AppEffects]),
        HttpClientModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        BrowserAnimationsModule,

    ],
    exports: [ReactiveFormsModule],
    providers: [
        LocalStorageService,
        StatusBar,
        SplashScreen,
        Network,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        File,
        FileOpener,
        FileTransfer,
        DocumentViewer,
        ToastController,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
