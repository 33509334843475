import { Action } from '@ngrx/store'
import { IClient, ClientLookupType } from 'src/types'

export enum ClientActionTypes {
    LoadClients = '[Client] Load Clients',
    LoadClientsSuccess = '[Client] Load Clients Success',
    LoadClientsFailure = '[Client] Load Clients Failure',
    LoadClientIntoSaleNote = '[Client] Load Client Into SaleNote',
    SetClientLookupType = '[Client] Set Client Lookup Type'
}

export class LoadClients implements Action {
    readonly type = ClientActionTypes.LoadClients
}

export class LoadClientsSuccess implements Action {
    readonly type = ClientActionTypes.LoadClientsSuccess
    constructor(public payload: { data: any }) {}
}

export class LoadClientsFailure implements Action {
    readonly type = ClientActionTypes.LoadClientsFailure
    constructor(public payload: { error: any }) {}
}

export class LoadClientIntoSaleNote implements Action {
    readonly type = ClientActionTypes.LoadClientIntoSaleNote
    constructor(
        public payload: {
            data: {
                client: IClient
                type: 'vendor' | 'purchaser'
                agentCode: string
                agentName: string
            }
        }
    ) {}
}

export class SetClientLookupType implements Action {
    readonly type = ClientActionTypes.SetClientLookupType
    constructor(
        public payload: { data: { type: ClientLookupType; search?: string } }
    ) {}
}

export type ClientActions =
    | LoadClients
    | LoadClientsSuccess
    | LoadClientsFailure
    | LoadClientIntoSaleNote
    | SetClientLookupType
