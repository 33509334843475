import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'home',
        loadChildren: './pages/home/home.module#HomePageModule',
    },

    {
        path: 'login',
        loadChildren: './pages/login/login.module#LoginPageModule',
    },
    {
        path: 'enter-sale-note',
        loadChildren:
            './pages/enter-sale-note/enter-sale-note.module#EnterSaleNotePageModule',
    },
    {
        path: 'lots',
        loadChildren:
            './pages/enter-sale-note/tabs/lots/lots.module#LotsPageModule',
    },
    {
        path: 'view-clients',
        loadChildren:
            './pages/view-clients/view-clients.module#ViewClientsPageModule',
    },
    {
        path: 'view-sale-notes',
        loadChildren:
            './pages/view-sale-notes/view-sale-notes.module#ViewSaleNotesPageModule',
    },
    // {
    //     path: 'settings',
    //     loadChildren: './pages/settings/settings.module#SettingsPageModule',
    // },
    {
        path: 'audit',
        loadChildren:
            './pages/enter-sale-note/tabs/audit/audit.module#AuditPageModule',
    },
    {
        path: 'view-pdf',
        loadChildren: './pages/view-pdf/view-pdf.module#ViewPdfPageModule',
    },
    {
        path: 'point-of-sale',
        loadChildren:
            './pages/point-of-sale/point-of-sale.module#PointOfSalePageModule',
    },
    {
        path: 'diary',
        loadChildren: './pages/diary/diary.module#DiaryPageModule',
    },
    {
        path: 'reports',
        loadChildren: './pages/reports/reports.module#ReportsPageModule',
    },
    {
        path: 'graphs',
        loadChildren: './pages/graphs/graphs.module#GraphsPageModule',
    },
    {
        path: 'admin',
        loadChildren: './pages/admin/admin.module#AdminPageModule',
    }
    //   {
    //     path: 'admin',
    //     loadChildren: () => import('./pages/admin/admin.module').then( m => m.AdminPageModule)
    //   }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    providers: [],
    exports: [RouterModule],
})
export class AppRoutingModule { }
