import { Action } from '@ngrx/store'
import { ToastOptions } from '@ionic/core'

export enum ToastMessageActionTypes {
    LoadToastMessage = '[ToastMessage] Load ToastMessages'
}

export class LoadToastMessage implements Action {
    readonly type = ToastMessageActionTypes.LoadToastMessage
    constructor(public payload: { data?: ToastOptions }) {}
}

export type ToastMessageActions = LoadToastMessage
