import { Action } from '@ngrx/store'
import { IAgentSettings } from 'src/types'

export enum AgentSettingsActionTypes {
    LoadAgentSettings = '[AgentSettings] Load AgentSettings',
    LoadAgentSettingsSuccess = '[AgentSettings] Load AgentSettings Success',
    LoadAgentSettingsFromPouchSuccess = '[AgentSettings] Load AgentSettings From Pouch Success',
    LoadAgentSettingsFailure = '[AgentSettings] Load AgentSettings Failure',
    LoadAgentSettingsFromPouch = '[AgentSettings] Load AgentSettings From Pouch',
    SyncAgentSettings = '[AgentSettings] Sync AgentSettings',
    ClearAgentSettings = '[AgentSettings] Clear Agent Settings'
}

export class LoadAgentSettingsFromPouch implements Action {
    readonly type = AgentSettingsActionTypes.LoadAgentSettingsFromPouch
}

export class LoadAgentSettingsFromPouchSuccess implements Action {
    readonly type = AgentSettingsActionTypes.LoadAgentSettingsFromPouchSuccess
    constructor(public payload: { data: IAgentSettings }) {}
}

export class LoadAgentSettings implements Action {
    readonly type = AgentSettingsActionTypes.LoadAgentSettings
    constructor(
        public payload: { data: { CID: string; pstrAgentCode: string } }
    ) {}
}

export class SyncAgentSettings implements Action {
    readonly type = AgentSettingsActionTypes.SyncAgentSettings
    constructor(public payload: { data: IAgentSettings }) {}
}

export class LoadAgentSettingsSuccess implements Action {
    readonly type = AgentSettingsActionTypes.LoadAgentSettingsSuccess
    constructor(public payload: { data: IAgentSettings }) {}
}

export class LoadAgentSettingsFailure implements Action {
    readonly type = AgentSettingsActionTypes.LoadAgentSettingsFailure
    constructor(public payload: { error: any }) {}
}

export class ClearAgentSettings implements Action {
    readonly type = AgentSettingsActionTypes.ClearAgentSettings
}

export type AgentSettingsActions =
    | LoadAgentSettings
    | LoadAgentSettingsSuccess
    | LoadAgentSettingsFailure
    | LoadAgentSettingsFromPouch
    | SyncAgentSettings
    | LoadAgentSettingsFromPouchSuccess
    | ClearAgentSettings
