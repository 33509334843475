import { Action } from '@ngrx/store'
import { SignatureTypes } from 'src/types'

export enum SignatureActionTypes {
    LoadSignature = '[Signature] Load Signatures',
    ClearSignature = '[Signature] Clear Signature',
    LoadSignaturesSuccess = '[Signature] Load Signatures Success',
    LoadSignaturesFailure = '[Signature] Load Signatures Failure'
}

export class LoadSignatures implements Action {
    readonly type = SignatureActionTypes.LoadSignature
    constructor(
        public payload: {
            data: {
                base64: string
                type: SignatureTypes
            }
        }
    ) {}
}

export class ClearSignature implements Action {
    readonly type = SignatureActionTypes.ClearSignature
    constructor(public payload: { data: { type: SignatureTypes } }) {}
}

export class LoadSignaturesSuccess implements Action {
    readonly type = SignatureActionTypes.LoadSignaturesSuccess
    constructor(public payload: { data: any }) {}
}

export class LoadSignaturesFailure implements Action {
    readonly type = SignatureActionTypes.LoadSignaturesFailure
    constructor(public payload: { error: any }) {}
}

export type SignatureActions =
    | LoadSignatures
    | LoadSignaturesSuccess
    | LoadSignaturesFailure
    | ClearSignature
