import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';

import PouchDBUpsert from 'pouchdb-upsert';

PouchDB.plugin(PouchDBUpsert);

// const PouchDB: PouchDB = require('pouchdb')

import {
    IAgentSettings,
    IGetAgentDataResponse,
    ISaleNote,
    MenuTypes,
    ILoginResponse,

} from 'src/types';
import { from, Observable, of } from 'rxjs';
import { docNotFound, clone, sortSaleNotes } from '../utilities';
import { flatMap, map, switchMap } from 'rxjs/operators';
import { saleNoteEmpty } from '../reducers';

const GET_AGENT_SETTINGS = 'GetAgentSettings';
const GET_AGENT_DATA = 'GetAgentData';
const GET_SALE_NOTES = 'GetSaleNotes';
const SALE_NOTES_DATE_RANGE = 'SaleNotesDateRange';
const SELECT_SALE_NOTE = 'SelectSaleNote';
const SALE_NOTES_FETCHED = 'SaleNotesFetched';

@Injectable({
    providedIn: 'root',
})
export class DbService {
    private db: PouchDB.Database;
    constructor() {
        this.db = new PouchDB('database');
    }

    setSaleNotesFetched(date: string) {
        return from(
            this.db.upsert(SALE_NOTES_FETCHED, () => {
                return { date };
            })
        );
    }

    getSaleNotesFetched(): Observable<{ date: null | string; }> {
        return from(
            this.db
                .get<{ date: null | string; }>(SALE_NOTES_FETCHED)
                .catch(() => ({ date: null }))
        );
    }

    setSelectedActiveSale(sale: string) {
        return from(
            this.db
                .upsert('selectedActiveSale', () => {
                    return { sale };
                })
                .then(() => undefined)
        );
    }

    getSelectedActiveSale() {
        return from(
            this.db
                .get<{ sale: string; }>('selectedActiveSale')
                .catch((error) => ({ sale: '' }))
                .then((doc) => doc.sale)
        );
    }

    saveLogo(logo: string) {
        return from(
            this.db
                .upsert('PDFLogo', () => {
                    return { logo };
                })
                .then(() => {
                    return this.db
                        .get<{ logo: string; }>('PDFLogo')
                        .then(({ logo }) => logo);
                })
        );
    }

    getLogo() {
        return from(
            this.db.get<{ logo: string; }>('PDFLogo').then(({ logo }) => logo)
        );
    }

    saveLoginResponse(loginResponse: ILoginResponse) {
        return from(
            this.db.upsert('LoginResponse', () => {
                return { loginResponse };
            })
        );
    }

    getLoginResponse() {
        return from(
            this.db.get<{ loginResponse: ILoginResponse; }>('LoginResponse')
        ).pipe(map(({ loginResponse }) => loginResponse));
    }

    setAllowedMenus(allowedMenus: string[]) {
        return from(
            this.db.upsert('AllowedMenus', () => {
                return { allowedMenus };
            })
        );
    }

    getAllowedMenus() {
        return from(
            this.db
                .get<{ allowedMenus: MenuTypes[]; }>('AllowedMenus')
                .catch(() => {
                    return { allowedMenus: [] as MenuTypes[] };
                })
        );
    }

    selectSaleNote(saleNote?: ISaleNote): Observable<ISaleNote> {
        if (saleNote) {
            saleNote = clone(saleNote);
            delete saleNote['__index'];
        }

        const promise = this.db
            .upsert<{ saleNote: ISaleNote; }>(SELECT_SALE_NOTE, () => {
                if (!saleNote) {
                    return { saleNote: saleNoteEmpty };
                } else {
                    return { saleNote };
                }
            })
            .then(() =>
                this.db
                    .get<{ saleNote: ISaleNote; }>(SELECT_SALE_NOTE)
                    .then((doc) => doc.saleNote)
            );

        return from(promise);
    }

    setSaleNotesDateRange(saleNotesDateRange: string) {
        const promise = this.db
            .get<{ saleNotesDateRange: string; }>(SALE_NOTES_DATE_RANGE)
            .then((doc) => {
                const updatedDoc = { ...doc, saleNotesDateRange };
                return this.db.put(updatedDoc);
            })
            .catch((error) => {
                if (docNotFound(error)) {
                    return this.db.put({
                        _id: SALE_NOTES_DATE_RANGE,
                        saleNotesDateRange: '3',
                    });
                } else {
                    return error;
                }
            });
        return from(promise);
    }

    getSaleNotesDateRange(): Observable<{ saleNotesDateRange: string; }> {
        const promise = this.db
            .get<{ saleNotesDateRange: string; }>(SALE_NOTES_DATE_RANGE)
            .catch((error) => {
                if (docNotFound(error)) {
                    return this.db
                        .put({
                            _id: SALE_NOTES_DATE_RANGE,
                            saleNotesDateRange: '3',
                        })
                        .then(() =>
                            this.db.get<{ saleNotesDateRange: string; }>(
                                SALE_NOTES_DATE_RANGE
                            )
                        );
                } else {
                    return error;
                }
            });
        return from(promise);
    }

    getSaleNotes() {
        const promise = this.db
            .get<{ saleNotes: ISaleNote[]; }>(GET_SALE_NOTES)
            .then((doc) => {
                doc.saleNotes = sortSaleNotes(doc.saleNotes);
                return doc;
            });
        return from(promise);
    }

    appendSaleNote(saleNote: ISaleNote) {
        return this.getSaleNotes().pipe(
            switchMap((saleNotes) => {
                return this.setSaleNotes([saleNote, ...saleNotes.saleNotes]);
            })
        );
    }

    setSaleNote(saleNote: ISaleNote) {
        return this.getSaleNotes().pipe(
            switchMap((doc) => {
                const newSaleNote = doc.saleNotes.every((savedSaleNote) => {
                    return (
                        savedSaleNote.saleNoteDetailsForm.saleNoteNumber !==
                        saleNote.saleNoteDetailsForm.saleNoteNumber
                    );
                });
                if (newSaleNote) {
                    doc.saleNotes.push(saleNote);
                    return this.setSaleNotes(doc.saleNotes);
                } else {
                    const saleNotes = doc.saleNotes.map((saleNoteMapped) => {
                        if (
                            saleNoteMapped.saleNoteDetailsForm
                                .saleNoteNumber ===
                            saleNote.saleNoteDetailsForm.saleNoteNumber
                        ) {
                            return saleNote;
                        } else {
                            return saleNoteMapped;
                        }
                    });
                    return this.setSaleNotes(saleNotes);
                }
            })
        );
    }

    setSaleNotes(
        saleNotes: ISaleNote[]
    ): Observable<{ saleNotes: ISaleNote[]; }> {
        saleNotes = saleNotes.map((saleNote) => {
            saleNote = clone(saleNote);
            try {
                delete saleNote['__index'];
            } catch (error) {
                saleNote['__index'] = undefined;
            }

            return saleNote;
        });

        const promise = this.db
            .get<{ saleNotes: ISaleNote[]; }>(GET_SALE_NOTES)
            .then((doc) => {
                const updatedDoc = { ...doc, saleNotes };
                return this.db.put(updatedDoc);
            })
            .catch((error) => {
                if (error.status === 404) {
                    return this.db.put({
                        _id: GET_SALE_NOTES,
                        saleNotes,
                    });
                }
                return error;
            });
        return from(promise);
    }

    deleteSaleNote(saleNote: ISaleNote) {
        return this.getSaleNotes().pipe(
            map(({ saleNotes }) => {
                return this.setSaleNotes(
                    saleNotes.filter((filterSaleNote) => {
                        return (
                            saleNote.saleNoteDetailsForm.saleNoteNumber ===
                            filterSaleNote.saleNoteDetailsForm.saleNoteNumber
                        );
                    })
                );
            })
        );
    }

    deleteAgentData() {
        const promise = this.db
            .get(GET_AGENT_DATA)
            .then((doc) => this.db.remove(doc))
            .then(() => this.db.get(GET_AGENT_SETTINGS))
            .then(doc => this.db.remove(doc))
            .then(() => this.db.get(GET_SALE_NOTES))
            .then(doc => this.db.remove(doc))
            .catch((error) => {
                return;
            });
        return from(promise);
    }

    getAgentData() {
        const promise = this.db.get<IGetAgentDataResponse>(GET_AGENT_DATA);
        return from(promise);
    }

    saveAgentData(agentData: IGetAgentDataResponse) {
        const promise = this.db
            .get<IGetAgentDataResponse>(GET_AGENT_DATA)
            .then((doc) => {
                const updatedDoc = { ...doc, ...agentData };
                return this.db.put<IGetAgentDataResponse>(updatedDoc);
            })
            .catch((error) => {
                if (error.status === 404) {
                    return this.db.put<IGetAgentDataResponse>({
                        _id: GET_AGENT_DATA,
                        ...agentData,
                    });
                }
                return error;
            });
        return from(promise);
    }

    getAgentSettings() {
        const promise = this.db.get<IAgentSettings>(GET_AGENT_SETTINGS);
        return from(promise);
    }

    saveAgentSettings(agentSettings: IAgentSettings) {
        const promise = this.db
            .get<IAgentSettings>(GET_AGENT_SETTINGS)
            .then((doc) => {
                const updatedDoc = { ...doc, ...agentSettings };
                return this.db.put<IAgentSettings>(updatedDoc);
            })
            .catch((error) => {
                if (error.status === 404) {
                    return this.db.put<IAgentSettings>({
                        _id: GET_AGENT_SETTINGS,
                        ...agentSettings,
                    });
                }
                return error;
            });
        return from(promise);
    }

    saveUser({
        pstrUserID,
        pstrPassword,
        CID,
    }: {
        pstrUserID: string;
        pstrPassword: string;
        CID: string;
    }) {
        return from(
            this.db.upsert('User', (oldDoc) => {
                return {
                    _id: 'User',
                    pstrUserID,
                    pstrPassword,
                };
            })
        );
    }

    getUser() {
        return from(
            this.db
                .get<{
                    pstrUserID: string;
                    pstrPassword: string;
                    CID: string;
                }>('User')
                .catch(() => ({ pstrUserID: '', pstrPassword: '', CID: '' }))
        );
    }   
}
